import styled, { keyframes } from "styled-components";
import LOGO from "../assets/svg/LOGO";

const LoadingPage = () => {
  return (
    <Container>
      <LogoContainer>
        <LOGO />
      </LogoContainer>
    </Container>
  );
};

const breatheAnimation = keyframes`
 0% { scale:0.9; }
 30% { scale:1; opacity: 1 }
 40% { scale:1.05; }
 100% { scale:0.9; }
`;

const LogoContainer = styled.div`
  width: 150px;
  animation: ${breatheAnimation} 2s ease-out infinite normal;
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  right: 0;
  left: 0;
  display: grid;
  place-content: center;
  background-color: rgba(255, 255, 255, 0.61);
  backdrop-filter: blur(5px);
  z-index: 999;
`;

export default LoadingPage;

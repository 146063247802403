import styled from "styled-components";
import Navbar from "../components/Navbar";
import Announcement from "../components/Announcement";
import FilterSide from "../components/FilterSide";
import { Footer } from "../components/Footer";
import { Products } from "../components/Products";
import { mobile } from "../responsive";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Tune } from "@material-ui/icons";
import { capitalizeFirstLetter } from "../helpers/textOptions";
import pathGenerator from "../utils/FilterPathGenerator";
import { Helmet } from "react-helmet";
import GTag from "../utils/GTag";
import MetaPixel from "../utils/metaPixel";

export const ProductList = () => {
  //for mobile side
  const [filterOpen, setFilterOpen] = useState(false);

  // const params = {
  //   ...(filter.colorsFiltered.length && { color: filter.colorsFiltered }),
  //   ...(filter.sizesFiltered.length && { size: filter.sizesFiltered }),
  //   ...(filter.brandsFiltered.length && { brand: filter.brandsFiltered }),
  //   ...(filter.genderFiltered.length && { gender: filter.genderFiltered }),
  //   ...(filter.categoryFiltered !== "" && {
  //     category: filter.categoryFiltered,
  //   }),
  //   ...(filter.sort !== "" && { sort: filter.sort }),
  //   ...(filter.inSold && { sold: filter.inSold }),
  // };

  //? noahXXX.com/products/ {category}     /      {gender}       /     {inslod}   /    {souCategory}
  //*                      cat || "all"   /  gender || "all"   / "insold"||"all" / souCat || "all" || null

  // const location = useLocation();
  // const path = location.pathname;
  // const cat = path.split("/")[2];
  // const gender = path.split("/")[3].split("-");
  // const sold = path.split("/")[4];

  const location = useLocation();
  const path = location.pathname;
  const history = useHistory();

  let cat = path.split("/")[2] || "all";
  let gender = path.split("/")[3]?.split("-") || "";
  // console.log({gender});
  let sold = path.split("/")[4] || "all";

  const currentPath = window.location.pathname;

  useEffect(() => {
    if (
      cat !== "shoes" &&
      cat !== "accessories" &&
      cat !== "clothes" &&
      cat !== "all"
    ) {
      const newPath = pathGenerator(currentPath, {
        target: "cat",
        value: "all",
      });
      history.push(newPath);
    }
    // eslint-disable-next-line
  }, [cat]);

  const title = document.title
  useEffect(()=>{
    if (document.title !== "Noah store" && document.title) {
      // Send GTAG data here
      // console.log(document.title);
      GTag();
    }
  },[title])

  return (
    <Container>
      <MetaPixel eventName={'prodcuct list page'}/>

      <Helmet>
        <title>
          {`${cat && capitalizeFirstLetter(cat)} ${
            gender && gender.join(" ")
          } `}
          - noah store
        </title>
      </Helmet>
      <Announcement />
      <Navbar />
      <div style={{ position: "relative" }}>
        <Title>
          .<i>{cat}</i>.
        </Title>
        <FilterContainer>
          <FilterOption onClick={() => setFilterOpen(true)}>
            <Tune />
            <FilterType>Filter</FilterType>
          </FilterOption>
        </FilterContainer>

        <ProductListContainer>
          <FilterSide
            filterOpen={filterOpen}
            setFilterOpen={setFilterOpen}
            cat={cat}
            gender={gender}
            sold={sold === "insold"}
          />
          <div>
            <Products />
          </div>
        </ProductListContainer>
      </div>
      <Footer />
    </Container>
  );
};

const FilterType = styled.p`
  font-size: 22px;
  font-weight: 600;
  color: black;
  margin-left: 10px;
`;

const FilterOption = styled.div`
  align-items: center;
  display: flex;
  border: 1px solid rgba(170, 170, 170, 0.411);
  border-radius: 15px;
  padding: 4px 10px;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.411);
`;

const ProductListContainer = styled.div`
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  position: relative;
  padding-inline: 30px;
  ${mobile({
    paddingInline: 0,
  })}
`;

const Container = styled.div`
  position: relative;
  ${mobile({
    display: "grid",
    placeContent: "center",
  })}
`;

const Title = styled.h1`
  /* margin: 20px; */
  text-transform: uppercase;
  font-weight: 600;
  padding-left: 30px;

  ${mobile({
    margin: "0px 20px",
    fontSize: 21,
    textAlign: "center",
    padding: 0,
  })}
`;

const FilterContainer = styled.div`
  position: sticky;
  top: 50px;
  display: none;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  margin: 20px;
  cursor: pointer;
  ${mobile({
    display: "flex",
    // backgroundColor: "white",
    zIndex: 1,
    padding: "20px 5px 5px 0px",
    margin: "0px 20px",
    justifyContent: "space-between",
  })}
`;

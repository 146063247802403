import { Email, Phone } from "@material-ui/icons";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import noahLogo from "../../assets/thanksLogo.png";
import { closeSuccessPage } from "../../redux/orderRedux";
import { mobile } from "../../responsive";

const SuccessPage = () => {
  const dispatch = useDispatch();
  return (
    <Container>
      <Card>
        <CloseContainer>
          <CloseBtn onClick={() => dispatch(closeSuccessPage())}>
            <CloseIcon />
          </CloseBtn>
        </CloseContainer>
        <ImgContainer>
          <LogoImg src={noahLogo} />
        </ImgContainer>
        <h1>THANK YOU!</h1>
        <DarkContainer>
          <h2>
            YOUR ORDER WAS PLACED <br />
            SUCCESSFULLY.
          </h2>
          <div
            style={{
              display: "flex",
              padding: "0 15px",
              alignItems: "center",
            }}
          >
            <Phone />
            <p>we will be calling you shortly to confirm your order.</p>
          </div>
          <div
            style={{
              display: "flex",
              padding: "0 15px",
              alignItems: "center",
            }}
          >
            <Email />
            <p>check your email for your order informations.</p>
          </div>
        </DarkContainer>
      </Card>
    </Container>
  );
};

const DarkContainer = styled.div`
  width: calc(100% - 20px);
  background-color: #212a2f;
  color: white;
  padding: 20px 5px;
  display: grid;
  place-content: center;
  letter-spacing: 1.5px;
  h2 {
    text-align: center;
    margin-bottom: 10px;
    ${mobile({
      fontSize: 18,
      padding: "0 15px",
      textAlign: "center",
    })}
  }
  p {
    margin: 8px 5px;
    padding: 0 15px;
    ${mobile({
      fontSize: 13,
      // padding: "0 15px",
    })}
  }
`;

const Card = styled.div`
  width: 400px;
  background-color: #fff;
  padding: 40px 20px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${mobile({
    width: "85vw",
    padding: "15px 5px",
  })}
`;

const ImgContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  display: block;
`;

const LogoImg = styled.img`
  width: 100%;
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  display: grid;
  place-content: center;
  background-color: rgba(0, 0, 0, 0.61);
  backdrop-filter: blur(5px);
  overflow: hidden;
  ${mobile({
    // padding: 20
  })}

  h1 {
    margin: 30px;
    letter-spacing: 0.8px;
    font-weight: 800;
    ${mobile({
      fontSize: 28,
    })}
  }
`;

const CloseContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
`;

const CloseBtn = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  transition: transform 250ms ease-in-out 0s;
  &:hover {
    transform: rotate(90deg);
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 50px;
  height: 50px;
  font-size: 0;
  &::after,
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 36px;
    background-color: black;
    transform: rotate(45deg) translate(-50%, -50%);
    transform-origin: top left;
    content: "";
  }
  &::after {
    transform: rotate(-45deg) translate(-50%, -50%);
  }
`;
export default SuccessPage;

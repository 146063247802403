import { DeleteOutline } from "@material-ui/icons";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { deleteProduct } from "../redux/cartRedux";
import { Link } from "react-router-dom";
import { mobile } from "../responsive";
import { BASE_URL } from "../requestMethods";

export const CartSidebar = ({ openSide, handleSideClick }) => {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const handleDeleteProduct = (id) => {
    dispatch(deleteProduct(id));
  };

  return (
    <div style={{ position: "fixed", top: 0, right: 0, zIndex: 9 }}>
      <BluredContainer
        openSide={openSide}
        onClick={handleSideClick}
      ></BluredContainer>

      <Container openSide={openSide}>
        {/* <BluredContainer> */}
        <CloseContainer onClick={handleSideClick}></CloseContainer>
        <CardContainer openSide={openSide}>
          <Header>
            <CloseBtn onClick={handleSideClick}>
              <CloseIcon></CloseIcon>
            </CloseBtn>
          </Header>
          <Content>
            <Products>
              {!cart.quantity && (
                <Buttons>
                  <ButtonsTitle>Your cart is empty..</ButtonsTitle>
                  <Link
                    to="/products/all/men"
                    style={{ textDecoration: "none" }}
                    onClick={handleSideClick}
                  >
                    <CheckOutButton>Men Shop</CheckOutButton>
                  </Link>
                  <Link
                    to="/products/all/women"
                    style={{ textDecoration: "none" }}
                    onClick={handleSideClick}
                  >
                    <CheckOutButton>Women Shop</CheckOutButton>
                  </Link>
                  <Link
                    to="/products/all/kids"
                    style={{ textDecoration: "none" }}
                    onClick={handleSideClick}
                  >
                    <CheckOutButton>Kids Shop</CheckOutButton>
                  </Link>
                  <Link
                    to="/products/all/all/insold"
                    style={{ textDecoration: "none" }}
                    onClick={handleSideClick}
                  >
                    <CheckOutButton
                      style={{ backgroundColor: "#eaad20", color: "white" }}
                    >
                      Sales
                    </CheckOutButton>
                  </Link>
                </Buttons>
              )}
              {cart.products.map((product, index) => (
                <div key={index}>
                  <Product>
                    <ProductImg>
                      <ProductQuantity>{product.quantity}</ProductQuantity>
                      <Img src={BASE_URL + "media/image/" + product.img} />
                    </ProductImg>
                    <Info>
                      <ProductTitle>{product.title}</ProductTitle>
                      <ProductRef>{product.ref}</ProductRef>
                      <ProductSize>
                        {" "}
                        <div style={{ textTransform: "lowercase" }}>
                          size: &nbsp;
                        </div>{" "}
                        {product.size}
                      </ProductSize>
                    </Info>
                    <Options>
                      <div onClick={() => handleDeleteProduct(product)}>
                        <DeleteOutline
                          style={{
                            fontSize: 30,
                            color: "#c23636",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <ProductPrice>
                        DA{" "}
                        {product.sold?.inSold
                          ? product.sold?.soldPrice
                          : product.price}
                      </ProductPrice>
                    </Options>
                  </Product>
                  <Hr />
                </div>
              ))}
            </Products>
          </Content>
          <CardOverView>
            <CartInfo>
              <InfoTexts>
                <FlexTitle>
                  <b>Subtotal</b>
                </FlexTitle>
                <FlexNumber>DA {cart.total}.00</FlexNumber>
              </InfoTexts>
            </CartInfo>
            <div style={{ padding: "0 24px" }}>
              <Link to="/checkout" style={{ textDecoration: "none" }}>
                <CheckOutButton
                  disabled={!cart.quantity}
                  isEmpty={!cart.quantity}
                >
                  Proceed to Checkout
                </CheckOutButton>
              </Link>
            </div>
          </CardOverView>
        </CardContainer>
        {/* </BluredContainer> */}
      </Container>
    </div>
  );
};

const ButtonsTitle = styled.div`
  font-size: 24px;
  color: rgb(33, 42, 47);
  letter-spacing: 0.5px;
  font-weight: 800;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InfoTexts = styled.div`
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
`;

const FlexNumber = styled.p``;
const FlexTitle = styled.p`
  font-weight: 500;
`;

const CartInfo = styled.div`
  padding: 24px 0;
  width: 100%;
`;

const CheckOutButton = styled.button`
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  padding: 13px 18px;
  font-weight: 700;
  border: 2px solid
    ${(props) => (props.isEmpty ? "rgb(211, 212, 213)" : "#212a2f")};
  border-radius: 5px;
  color: #fff;
  background-color: ${(props) =>
    props.isEmpty ? "rgb(211, 212, 213)" : "#212a2f"};
  width: 100%;
  transition: color 0.25s ease-out, background-color 0.25s ease-out,
    border-color 0.25s ease-out;
  appearance: none;
  cursor: ${(props) => (props.isEmpty ? "default" : "pointer")};
  &:hover {
    background-color: ${(props) => (props.isEmpty ? "" : "#fff")};
    color: ${(props) => (props.isEmpty ? "" : "#212a2f")};
    font-weight: 700;
  }
`;
const CardOverView = styled.div`
  /* padding: 0 24px; */
  position: sticky;
  bottom: 0;
  padding-bottom: 30px;
  background-color: #fff;
`;

const ProductQuantity = styled.div`
  position: absolute;
  top: -8px;
  left: -8px;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background-color: rgb(33, 42, 47);

  color: white;
  display: grid;
  place-content: center;
`;

const Img = styled.img`
  width: 100%;
  object-fit: contain;
  box-sizing: border-box;
  border: 1px solid #919191;
  border-radius: 6px;
`;

const Products = styled.div`
  /* width: 90%; */
  padding: 24px;
`;
const Product = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  color: #323232;
  margin-bottom: 7px;
  padding: 24px 0;
`;

const ProductImg = styled.div`
  width: 120px;
  border-radius: 8px;
  background: transparent;
  margin-right: 10px;
  position: relative;
`;

//   const ProductPrice = styled.div`
//   width: 110px;
//   display: flex;
//   justify-content: right;
// `;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 0px;
`;
const ProductTitle = styled.div`
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
`;
const ProductSize = styled.div`
  display: flex;
  font-size: 0.8571428571em;
  font-weight: 300;
  color: #5e5e5e;
  text-transform: uppercase;
`;

const CloseContainer = styled.div`
  width: 100%;
  background-color: transparent;
  cursor: pointer;
`;

// const DeleteProduct = styled.div`
//   cursor: pointer;
// `;

const ProductPrice = styled.p`
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  white-space: nowrap;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  /* -webkit-box-pack: justify; */
  justify-content: space-between;
  align-items: flex-end;
  height: 90px;
`;

const Hr = styled.hr`
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border: none;
  height: 1px;
  margin: 0px;
  background-color: rgb(211, 212, 213);
`;

const ProductRef = styled.div`
  margin: 0px;
  line-height: 20px;
  color: rgb(33, 42, 47);
  letter-spacing: 0.5px;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CloseBtn = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  transition: transform 250ms ease-in-out 0s;
  &:hover {
    transform: rotate(90deg);
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 50px;
  height: 50px;
  font-size: 0;
  &::after,
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 36px;
    background-color: black;
    transform: rotate(45deg) translate(-50%, -50%);
    transform-origin: top left;
    content: "";
  }
  &::after {
    transform: rotate(-45deg) translate(-50%, -50%);
  }
`;

const Header = styled.div`
  padding: 24px 10px;
  /* position: relative; */
`;

const Content = styled.div`
  /* padding: 0 24px; */
  width: 100%;
`;

const CardContainer = styled.div`
  width: 480px;
  overflow-y: scroll;
  z-index: 9;
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  grid-template-rows: max-content 1fr max-content;
  /* row-gap: 2rem; */
  transition: 1s ease;
  max-width: 1024px;
  display: grid;
  grid-template-columns: 100%;
  ${mobile({
    width: "100vw",
  })}
`;
const BluredContainer = styled.div`
  position: fixed;
  /* display: flex; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  visibility: ${(props) => !props.openSide && "hidden"};
  /* z-index: 999; */
  background-color: ${(props) =>
    !props.openSide ? "transparent" : "rgba(0, 0, 0, 0.61)"};
  backdrop-filter: ${(props) => (!props.openSide ? "blur(0px)" : "blur(5px)")};
  transition: 150ms ease;
  cursor: pointer;
  ${mobile({
    display: "none",
  })}
`;
const Container = styled.div`
  position: fixed;
  top: 0;
  right: ${(props) => (!props.openSide ? "-480px" : 0)};
  height: 100vh;
  /* width: 100vw; */
  /* overflow: hidden; */
  /* background-color: ${(props) =>
    !props.openSide ? "transparent" : "rgba(0, 0, 0, 0.61)"}; */
  /* backdrop-filter: ${(props) => (!props.openSide ? null : "blur(5px)")}; */
  display: flex;
  flex-direction: row;
  z-index: 5;
  transition: all 350ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  box-sizing: border-box;
`;

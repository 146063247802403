import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { handleSetPreFilter, resetFilters } from "../redux/filterRedux";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  const dispatch = useDispatch()
  const { preFilter } = useSelector((state) => state.filter);


  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // Optional if you want to skip the scrolling animation
    });
    // reset all filter redux states to default
    if (preFilter) {
      dispatch(handleSetPreFilter(false));
      return
    }
    dispatch(resetFilters())
    // eslint-disable-next-line
  }, [pathname]);

  return null;
}
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import { Categories } from "../components/Categories";
import { Footer } from "../components/Footer";
import { GenderCategories } from "../components/GenderCategories";
import HScroll from "../components/HScroll";
import Navbar from "../components/Navbar";
// import { Slider } from "../components/Slider";
import SuccessPage from "../components/popup/SuccessPage";
import BrandsFooter from "../components/BrandsFooter";
import { mobile } from "../responsive";
import DesktopMainImg2 from "../assets/images/home/MainDesktop2.jpg";
import MobileMainImg2 from "../assets/images/home/MobileMain2.jpg";
import LOGOwhiteBold from "../assets/LOGOwhiteBold.png";
import BluevaLogo from "../assets/images/home/bluevaLogo.png";

import { Helmet } from "react-helmet";
import GTag from "../utils/GTag";
import MetaPixel from "../utils/metaPixel";
import { addBrandsFiltered, handleSetPreFilter } from "../redux/filterRedux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const MainBanner = styled.div`
  width: 100%;
  position: relative;
`;

const DesktopImg = styled.img`
  width: 100%;
  ${mobile({
    display: "none",
  })}
`;
const MobileImg = styled.img`
  width: 100%;
  display: none;
  ${mobile({
    display: "block",
  })}
`;
const FlipedText = styled.div`
  color: white;
  position: absolute;
  top: 50%;
  right: 4vw;
  transform: translate(50%, -50%);
  transform: rotate(90deg);
  p {
    font-size: 23px;
    letter-spacing: 3px;
    font-weight: 700;
    text-shadow: 2px 2px #eaad20;
  }
  ${mobile({
    display: "none",
  })}
`;

const MainBannerContent = styled.div`
  position: absolute;
  top: 50%;
  /* left: 2vw; */
  transform: translate(30%, -50%);
  background-color: #0808081a;
  backdrop-filter: blur(1px);
  /* box-shadow: 1px 1px #eaad20; */
  ${mobile({
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  })}

  padding: 20px;
`;
const MainBannerContentHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  img {
    width: 120px;
    ${mobile({
      width: 90,
    })}
  }
`;

const MainBannerContentText = styled.div`
  width: 570px;
  font-size: 23px;
  font-weight: 600;
  letter-spacing: 1.5px;
  color: white;
  margin-bottom: 20px;
  ${mobile({
    width: "100%",
    fontSize: 16,
    textAlign: "center",
  })}
`;

const MainBannerContentButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
`;

const MainBannerContentButton = styled.div`
  width: 120px;
  padding: 13px 15px;
  font-weight: 600;
  color: black;
  display: grid;
  place-content: center;
  background-color: white;
  transition: color 0.25s ease-out, background-color 0.25s ease-out;
  box-shadow: 2px 2px #eaad20;
  ${mobile({
    // width: 70,
    fontSize: 13,
    boxSizing: "border-box",
  })}

  &:hover {
    background-color: #eaad20;
    color: white;
  }

  cursor: pointer;
`;
const BluevaButton = styled.div`
  padding: 5px 10px;
  font-weight: 600;
  color: black;
  display: grid;
  place-content: center;
  background-color: white;
  transition: color 0.25s ease-out, background-color 0.25s ease-out;
  box-shadow: 4px 4px #29859a;
  ${mobile({
    // width: 70,
    fontSize: 13,
    boxSizing: "border-box",
  })}

  &:hover {
    background-color: #c4e4eb;
    color: white;
  }

  cursor: pointer;
`;

const VerLine = styled.div`
  width: 1px;
  background-color: white;
`;

const Home = () => {
  const openSuccessPage = useSelector((state) => state.order.openSuccessPage);

  // wait page title change to send data to google analytics.
  useEffect(() => {
    GTag();
  }, []);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <MetaPixel eventName={"home-page"} />

      <Helmet>
        <title>Noah store</title>
        <meta
          name="description"
          content="NOAH STORE!. Discover our selection of baskets, clothing and accessories from major brands such as Adidas, Nike, Puma, Reebok and many more."
        />
      </Helmet>
      <Announcement />
      <Navbar />
      {openSuccessPage && <SuccessPage />}
      {/* <SuccessPage/> */}
      {/* //! Main Banner */}
      <MainBanner>
        <FlipedText>
          <p>-ORIGINAL PRODUCTS-</p>
        </FlipedText>
        <MainBannerContent>
          <MainBannerContentHeader>
            <img src={LOGOwhiteBold} alt="header white logo" />
          </MainBannerContentHeader>

          <MainBannerContentText>
            Welcome to Noah Store's new website! Discover the latest fashion
            trends and shop for original brands at our online store.
          </MainBannerContentText>

          <MainBannerContentButtons>
            <Link to="/products/all/men" style={{ textDecoration: "none" }}>
              <MainBannerContentButton>Men Shop</MainBannerContentButton>
            </Link>
            <VerLine />
            <Link to="/products/all/women" style={{ textDecoration: "none" }}>
              <MainBannerContentButton>Women Shop</MainBannerContentButton>
            </Link>
          </MainBannerContentButtons>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div
              onClick={() => {
                dispatch(addBrandsFiltered(["blueva"]));
                dispatch(handleSetPreFilter(true));
                history.push("/products/");
              }}
              style={{ textDecoration: "none", background: "white" }}
            >
              <BluevaButton>
                <img src={BluevaLogo} alt="blueva logo" />
              </BluevaButton>
            </div>
          </div>
        </MainBannerContent>
        <DesktopImg src={DesktopMainImg2} alt="main-pic" />
        <MobileImg src={MobileMainImg2} alt="mobile-main-pic" />
      </MainBanner>
      <BrandsFooter />

      <Section style={{ marginTop: 0 }}>
        <SectionTitle>who are you shopping for?</SectionTitle>
        <SectionContent>
          <GenderCategories />
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle>Latest Products</SectionTitle>
        <SectionContent>
          <HScroll
            params={{
              limit: 12,
            }}
          />
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle style={{ maxWidth: 200, margin: "auto" }}>
          <BluevaButton
            onClick={() => {
              dispatch(addBrandsFiltered(["blueva"]));
              dispatch(handleSetPreFilter(true));
              history.push("/products/");
            }}
          >
            <img src={BluevaLogo} alt="blueva logo" />
          </BluevaButton>
        </SectionTitle>
        <SectionContent>
          <HScroll
            params={{
              limit: 12,
              brand: ["blueva"],
            }}
          />
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle>What are you shopping?</SectionTitle>
        <SectionContent>
          <Categories />
        </SectionContent>
      </Section>

      <Section>
        <Link to="/products/all/all/insold">
          <SectionTitle sold>sale!</SectionTitle>
        </Link>
        <SectionContent>
          <HScroll
            params={{
              sold: "insold",
              limit: 12,
            }}
          />
        </SectionContent>
      </Section>

      <BrandsFooter />
      <Footer />
    </>
  );
};

const SectionContent = styled.div`
  display: flex;
  justify-content: center;
`;

const SectionTitle = styled.p`
  padding-inline: 25px;
  font-size: 28px;
  line-height: 30px;
  color: ${(props) => (props.sold ? "#eaad20" : "#212a2f")};
  text-decoration: ${(props) => (props.sold ? "underline" : "none")};
  cursor: ${(props) => (props.sold ? "pointer" : null)};
  font-weight: 600;
  text-transform: capitalize;
  ${mobile({
    fontSize: 20,
    flexDirection: "column",
  })}
`;
const Section = styled.div`
  margin: 100px 0;
  p {
    text-align: center;
  }
`;

export default Home;

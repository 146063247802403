import { createSlice } from '@reduxjs/toolkit'

const filterSlice = createSlice({
    name: "filter",
    initialState: {
        colorsFiltered: [],
        sizesFiltered: [],
        brandsFiltered: [],
        genderFiltered: [],
        sort: '',
        categoryFiltered: '',
        distinctSizes: [],
        distinctBrands: [],
        inSold: false,
        preFilter: false,
    },
    reducers: {
        addColorsFiltered: (state, action) => {
            state.colorsFiltered = action.payload
        },
        addSizesFiltered: (state, action) => {
            state.sizesFiltered = action.payload
        },
        addBrandsFiltered: (state, action) => {
            state.brandsFiltered = action.payload
        },
        addGenderFiltered: (state, action) => {
            state.genderFiltered = action.payload
        },
        addSort: (state, action) => {
            state.sort = action.payload
        },
        addInSold: (state, action) => {
            state.inSold = action.payload
        },
        addCategoryFiltered: (state, action) => {
            state.categoryFiltered = action.payload
        },
        resetFilters: (state) => {
            state.colorsFiltered = []
            state.sizesFiltered = []
            state.brandsFiltered = []
            state.genderFiltered = []
            state.sort = ''
            state.inSold = false
            state.categoryFiltered = ''
        },
        handleSetPreFilter: (state, action) => {
            state.preFilter = action.payload
        }

    }
})
export const { addBrandsFiltered, addColorsFiltered, addGenderFiltered, addSizesFiltered, addSort, addCategoryFiltered, addInSold, resetFilters, handleSetPreFilter } = filterSlice.actions
export default filterSlice.reducer
import {
  Add,
  Remove,
  Close,
  LocalShipping,
  LocalMall,
  VerifiedUser,
} from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import Announcement from "../components/Announcement";
import { Footer } from "../components/Footer";
import Navbar from "../components/Navbar";
import { addProduct } from "../redux/cartRedux";
import { BASE_URL, publicRequest } from "../requestMethods";
import { mobile } from "../responsive";
import HScroll from "../components/HScroll";
import BrandsFooter from "../components/BrandsFooter";
import LoadingPage from "../components/LoadingPage";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { Helmet } from "react-helmet";
import GTagUseEffect from "../googleAnalytics/GTagUseEffect";
import AddedToCart from "../components/popup/AddedToCart";
import MetaPixel from "../utils/metaPixel";

export const Product = () => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [product, setProduct] = useState({
    title: null,
    desc: "NOAH STORE!. Discover our selection of baskets, clothing and accessories from major brands such as Adidas, Nike, Puma, Reebok and many more.",
  });
  const [linkToProducts, setLinkToProducts] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [size, setSize] = useState(null);
  const [clickable, setClickable] = useState("grey");
  const [openGallery, setOpenGallery] = useState(false);
  const [productGender, setProductGender] = useState(false);

  const [itemAdded, setItemAdded] = useState(false);
  const [sizeValue, setSizeValue] = useState("0");

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  function handleQuantity(param) {
    if (param === "dec") {
      quantity > 1 && setQuantity(quantity - 1);
    } else {
      if (size) {
        if (quantity < size.quantity) {
          setQuantity(quantity + 1);
        } else {
          console.log("max quantity");
        }
      } else {
        console.log("select a size");
      }
    }
  }

  const handleClick = () => {
    if (!size) {
      setClickable("red");
      return console.log("select a size");
    }
    dispatch(
      addProduct({
        img: product.img[0],
        title: product.title,
        price: product.price,
        sold: product.sold,
        _id: product._id,
        color: product.color,
        size: size.size,
        ref: product.ref,
        quantity,
      })
    );
    setItemAdded(true);
    setSize(null);
  };

  const handleSizeChange = (index, e) => {
    if (product.attr[index].quantity > 0) {
      const clickedSize = product.attr[index];
      setQuantity(1);
      setClickable("green");
      setSize({ ...clickedSize, index });
      setSizeValue(clickedSize?.size);
    }
  };

  const redirect = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    const getProduct = async () => {
      try {
        setLoading(true);
        const res = await publicRequest.get(`/products/find/${id}`);
        const data = res.data;
        // document.title = data.title + " - noah store";
        setLoading(false);
        setProduct(data);
        // GTag()
        //reset prev selected size and quantity
        setQuantity(0);
        setSize(null);
        setProductGender(data.gender);
      } catch (err) {
        redirect();
        console.log(err);
      }
    };

    getProduct();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    const getLinkToProducts = async (linkTo) => {
      try {
        const res = await publicRequest.get(`/products/linkTo/${linkTo}`);
        setLinkToProducts(res.data);
      } catch (err) {
        setLinkToProducts([]);
        console.log(err);
      }
    };
    getLinkToProducts(product?.linkTo);
  }, [product, id]);

  GTagUseEffect();

  const handleCartOpen = () => {
    setItemAdded(false);
  };

  return (
    <Container>
      {product.title && <MetaPixel eventName={product.title} />}

      <Helmet>
        <title>{`${product.title ?? ""} - noah store`}</title>
        <meta name="description" content={product.desc} />
      </Helmet>
      <Announcement />
      <Navbar />
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          {itemAdded && (
            <AddedToCart
              product={product}
              size={sizeValue}
              quantity={quantity}
              handleCartOpen={handleCartOpen}
            />
          )}
          <div
            style={{
              width: "100%",
              display: "grid",
              placeContent: "center",
              marginTop: 0,
            }}
          >
            <Wrapper>
              <ImgContainer onClick={() => setOpenGallery(true)}>
                {product.img?.map((img, index) => (
                  <Image
                    key={index}
                    src={BASE_URL + "media/image/" + img}
                    loading="eager"
                  />
                ))}
              </ImgContainer>
              <SwiperGalery>
                <Swiper
                  slidesPerView={1}
                  // spaceBetween={1}
                  initialSlide={1}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={false}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                >
                  {product.img?.map((img, index) => (
                    <SwiperSlide key={index}>
                      <SwiperImageContainer>
                        <img
                          key={index}
                          src={BASE_URL + "media/image/" + img}
                          alt="img"
                        />
                      </SwiperImageContainer>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </SwiperGalery>

              <InfoContainer>
                <Title>
                  {product.title}{" "}
                  {product.sold?.inSold && (
                    <Percenage>
                      &nbsp;
                      {Math.floor(
                        (product.sold?.soldPrice / product.price - 1) * 100
                      )}{" "}
                      %&nbsp;
                    </Percenage>
                  )}{" "}
                </Title>
                <Ref>{product?.ref}</Ref>
                <Desc>{product.desc}</Desc>
                <Sticky>
                  <Prices>
                    <Price insold={product.sold?.inSold}>
                      {" "}
                      DA{" "}
                      {product.sold?.inSold
                        ? product.sold?.soldPrice
                        : product.price}
                    </Price>
                    {product.sold?.inSold && (
                      <SoldPrice>DA {product.price} </SoldPrice>
                    )}
                  </Prices>
                  <FilterContainer>
                    <ColorChoices>
                      {linkToProducts.length > 1 ? (
                        linkToProducts.map((item, index) => (
                          <Link to={`/product/${item._id}`} key={index}>
                            <ColorChoice
                              key={index}
                              selected={item._id === product._id}
                            >
                              <img
                                src={BASE_URL + "media/image/" + item?.img[0]}
                                alt=""
                              />
                            </ColorChoice>
                          </Link>
                        ))
                      ) : (
                        <Filter>
                          <FilterTitle>Color</FilterTitle>
                          <FitlerColor color={product.color}></FitlerColor>
                        </Filter>
                      )}
                    </ColorChoices>
                  </FilterContainer>
                  <Desc>Select a Size: </Desc>
                  <Sizes clickable={clickable}>
                    {product.attr?.map((item, index) => (
                      <Size
                        key={index}
                        style={{
                          textTransform: "uppercase",
                        }}
                        index={index}
                        selectedIndex={size}
                        quantity={item.quantity}
                        onClick={(e) => handleSizeChange(index, e)}
                      >
                        {item.size}
                        {/* <i>({item.quantity})</i> */}
                      </Size>
                    ))}
                  </Sizes>
                  <HandleAdd>
                    <AddContainer>
                      <AmountContainer>
                        <Remove onClick={() => handleQuantity("dec")} />
                        <Amount>{quantity}</Amount>
                        <Add onClick={() => handleQuantity("inc")} />
                      </AmountContainer>
                    </AddContainer>
                    <Button clickable={clickable} onClick={handleClick}>
                      ADD TO CART
                    </Button>
                  </HandleAdd>
                  {clickable === "red" && <Warn>Please select a size</Warn>}
                  <Highlights>
                    <p>
                      {" "}
                      <VerifiedUser />
                      Products 100% Original
                    </p>
                    <hr />
                    <p>
                      {" "}
                      <LocalShipping /> Fast delivery
                    </p>
                    <hr />
                    <p>
                      {" "}
                      <LocalMall /> Payment on delivery
                    </p>
                  </Highlights>
                </Sticky>
              </InfoContainer>
            </Wrapper>
          </div>
          <Gallery openGallery={openGallery}>
            {/* <CloseGallery> */}
            <Close style={close} onClick={() => setOpenGallery(false)} />
            {/* </CloseGallery> */}
            <ImgContainerGallery>
              {product.img?.map((img, index) => (
                <ImageGallery
                  key={index}
                  src={BASE_URL + "media/image/" + img}
                />
              ))}
            </ImgContainerGallery>
          </Gallery>
          {productGender && (
            <div style={{ width: "100%" }}>
              <Section>
                <SectionTitle sold>you may like !</SectionTitle>
                <SectionContent>
                  <HScroll
                    params={{
                      gender:
                        productGender === "unisex"
                          ? ["men", "women"]
                          : productGender || "",
                      random: 4,
                    }}
                  />
                </SectionContent>
              </Section>
            </div>
          )}

          <BrandsFooter />
          <Footer />
        </>
      )}
    </Container>
  );
};

const Highlights = styled.div`
  margin-top: 30px;
  display: inline-block;
  padding: 10px;
  /* background-color: rgb(245, 245, 245); */

  p {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 15px;
    margin-top: 10px;
    color: #373737;
    display: flex;
    align-items: center;
    gap: 15px;
    font-weight: 500;
    ${mobile({
      fontSize: 20,
    })}
  }
  hr {
    background-color: rgb(200, 199, 199);
    border: none;
    height: 1px;
  }
`;

const HandleAdd = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  ${mobile({
    display: "block",
    width: "100%",
  })}/* justify-content: center; */
`;

const Percenage = styled.p`
  color: white;
  font-size: 17px;
  padding: 1px 3px;
  white-space: nowrap;
  /* position: absolute; */
  /* top: 0; */
  /* right: -40px; */
  /* width: 50px; */
  height: 30px;
  /* width: 120px; */
  /* border-radius: 50px; */
  display: grid;
  align-items: center;
  font-weight: bold;
  background-color: red;
  /* z-index: 999; */
`;
const SectionContent = styled.div`
  display: flex;
  justify-content: center;
`;

const SectionTitle = styled.p`
  padding-inline: 25px;
  font-size: 28px;
  line-height: 30px;
  color: #212a2f;
  font-weight: 600;
  text-transform: capitalize;
  ${mobile({
    fontSize: 20,
    flexDirection: "column",
  })}
`;
const Section = styled.div`
  margin: 100px 0;
  p {
    text-align: center;
  }
`;
const ColorChoices = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 4px;
  align-items: center;
`;

const ColorChoice = styled.div`
  border-radius: 4px;
  width: 80px;
  min-height: 80px;
  max-height: 100px;
  border: 2px solid ${(props) => (props.selected ? "black" : "#f1f1f1")};
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  ${mobile({})}
  img {
    width: 100%;
  }
  :hover {
    border: 2px solid black;
  }
`;

const SwiperImageContainer = styled.div`
  width: 100%;
  img {
    width: 100%;
  }
`;

const SwiperGalery = styled.div`
  /* padding: 20px; */
  width: 100vw;
  display: none;
  ${mobile({
    display: "block",
  })}
`;

const Sticky = styled.div`
  background-color: #fff;
  position: sticky;
  top: 100px;
  margin-bottom: 200px;
  ${mobile({
    display: "flex",
    flexDirection: "column",
    padding: "0 10px",
    // placeContent: "center",
    alignItems: "center",
    marginBottom: 0,
  })}
`;

const close = {
  fontSize: "40px",
  position: "fixed",
  top: "20px",
  right: "40px",
  cursor: "pointer",
};

const ImageGallery = styled.img`
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
`;

const ImgContainerGallery = styled.div`
  width: 80%;
  height: 80%;
  padding-inline: 200px;
  box-sizing: border-box;
`;

const Gallery = styled.div`
  /* z-index: 999; */
  position: absolute;
  z-index: 3;
  min-height: calc(100vh);
  min-width: calc(100vw);
  background-color: white;
  top: 0;
  right: 0;
  display: ${(props) => (props.openGallery ? "flex" : "none")};
  align-items: center;
  flex-direction: column;
`;

const Warn = styled.p`
  margin-top: 10px;
  color: red;
`;

const Sizes = styled.div`
  /* display: grid; */
  /* grid-template-columns: repeat(3, 1fr); */
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  /* width: 300px; */
  margin-bottom: 30px;
  margin-top: 20px;
  padding: 2px;
  /* border: 1px solid; */
  /* ${(props) => (props.clickable === "red" ? "#631212" : "transparent")}; */
  ${mobile({
    width: "100%",
    // placeContent: "center",
    marginTop: 0,
  })}
`;
const Size = styled.div`
  width: 55px;
  display: grid;
  place-content: center;
  font-weight: 500;
  text-decoration: ${(props) => (props.quantity > 0 ? null : "line-through")};
  border: ${(props) =>
    props.quantity === 0
      ? "1px solid red"
      : props.index === props.selectedIndex?.index
      ? "3px solid black"
      : "1px solid black"};
  height: 60px;
  box-sizing: border-box;
  color: ${(props) => (props.quantity === 0 ? "grey" : "black")};

  color: ${(props) =>
    props.index === props.selectedIndex?.index ? "white" : null};
  background-color: ${(props) =>
    props.index === props.selectedIndex?.index ? "#212a2f" : null};
  cursor: ${(props) => (props.quantity === 0 ? "not-allowed" : "pointer")};
  &:hover {
    border: ${(props) => (props.quantity === 0 ? null : "2px solid black")};
  }
`;

const Ref = styled.p`
  text-transform: uppercase;
  font-size: 23px;
  font-weight: 200;
  color: grey;
  ${mobile({
    padding: "0 10px",
  })}
`;

const Prices = styled.div`
  display: flex;
  align-items: center;
  ${mobile({
    width: "100%",
    // textAlign: "left"
  })}
`;
const SoldPrice = styled.p`
  font-size: 30px;
  font-weight: 200;
  text-decoration: line-through;
  margin-left: 20px;
  color: #5a5a5a;
`;

const Container = styled.div`
  position: relative;
  /* display: grid; */
  /* place-content: center; */
`;

const Wrapper = styled.div`
  display: flex;
  /* padding: 50px 300px; */
  /* width: 80%; */
  margin-top: 20px;

  max-width: 1440px;
  /* width: 100%; */
  ${mobile({
    padding: "0",
    flexDirection: "column",
    marginTop: 0,
  })}
`;

const ImgContainer = styled.div`
  flex: 1.7;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 7px;
  /* flex: 2; */
  ${mobile({
    display: "none",
  })}
`;

const Image = styled.img`
  width: 100%;
  /* height: 90vh; */
  object-fit: cover;
  ${mobile({
    height: "40vh",
  })}
`;

const InfoContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 0 50px;
  ${mobile({
    padding: "0 10px",
  })}
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 32px;
  line-height: 1.31;
  color: #212a2f;
  text-transform: capitalize;
  display: flex;
  align-items: flex-start;
  /* gap: 20px; */
  position: relative;
  ${mobile({
    padding: "0 10px",
    fontSize: 30,
  })}
`;

const Desc = styled.p`
  max-width: 500px;
  overflow-wrap: break-word;
  margin: 20px 0;
  letter-spacing: 1px;
  line-height: 1.5;
  ${mobile({
    margin: "20px 0 5px",
    padding: "0 10px",
  })}
`;

const Price = styled.span`
  font-weight: 300;
  font-size: 40px;
  color: ${(props) => (props.insold ? "red" : "black")};
  /* color: black; */
`;

const FilterContainer = styled.div`
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
  ${mobile({
    // width: "90%",
    margin: "5px 0px",
  })}
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
`;

const FilterTitle = styled.span`
  font-size: 20px;
  font-weight: 200;
  margin-right: 10px;
`;
const FitlerColor = styled.div`
  border: 1px solid #a8a9aa;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 5px;
  cursor: pointer;
`;

const AddContainer = styled.div`
  /* width: 50%; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mobile({
    width: "100%",
  })}
`;
const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;

  height: 100%;
`;
const Amount = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid #eaad20;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 0 5px; */
`;
const Button = styled.button`
  /* padding: 15px;
  /* border: 1px solid teal; */
  /* border: 1px solid ${(props) => props.clickable}; */
  /* background-color: #fff;
  transition: all 0.5s;
  font-weight: 500;  */
  /* &:hover {
    background-color: ${(props) =>
    props.clickable === "red" ? "#803c0029" : "#00808029"};
  } */
  /* display: block; */
  /* margin-top: 20px; */
  width: 320px;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  padding: 13px 18px;
  font-weight: 700;
  border: 2px solid
    ${(props) => (props.clickable === "red" ? "gray" : "#212a2f")};
  border-radius: 5px;
  color: white;
  background-color: ${(props) =>
    props.clickable === "red" ? "gray" : "#212a2f"};
  transition: color 0.25s ease-out, background-color 0.25s ease-out,
    border-color 0.25s ease-out;
  appearance: none;
  cursor: ${(props) => (props.clickable === "red" ? "default" : "pointer")};
  &:hover {
    background-color: ${(props) =>
      props.clickable === "red" ? "gray" : "white"};
    color: ${(props) => (props.clickable === "red" ? "white" : "#212a2f")};
    font-weight: 700;
  }
  ${mobile({
    // minWidth: "300px",
    width: "100%",
    marginTop: 20,
  })}
`;

import { useEffect } from "react";
import GTag from "../utils/GTag";

const GTagComponent = () => {
  // wait page title change to send data to google analytics.
  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (document.title !== "Noah store" && document.title) {
        // Send GTAG data here
        // console.log(document.title);
        GTag();
      }
    });
    observer.observe(document.querySelector("title"), {
      subtree: true,
      characterData: true,
      childList: true,
    });

    return () => observer.disconnect();
  }, []);
};
export default GTagComponent;

import React from "react";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./swiper.css";
import { Product } from "./Product";
import { BASE_URL } from "../requestMethods";
import { mobile } from "../responsive";

function HScroll({ params }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const res = await axios.get(`${BASE_URL}products`, { params });
        setProducts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getProducts();
  }, [params]);

  return (
    <Container>
      <Swiper
        style={
          {
            // padding: "30px 50px"
          }
        }
        // install Swiper modules
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={50}
        // slidesPerView={5}
        breakpoints={{
          360: {
            slidesPerView: 1,
            spaceBetween: 1,
          },
          789: {
            slidesPerView: 2,
            spaceBetween: 5,
          },

          1168: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1650: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          1950: {
            slidesPerView: 5,
            spaceBetween: 30,
          },
        }}
        // navigation
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={{ dynamicBullets: true, clickable: true }}
        scrollbar={{ draggable: true }}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log("slide change")}
      >
        {products.map((item, index) => (
          <SwiperSlide key={index}>
            <Product item={item} hscroll />
          </SwiperSlide>
        ))}
        <NavigationButton className="swiper-button-prev" direction="prev" />
        <NavigationButton className="swiper-button-next" direction="next" />
        {/* <SwiperSlide></SwiperSlide> */}
      </Swiper>
    </Container>
  );
}
const NavigationButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 40px;
  height: 40px;
  background-color: #fff;
  box-shadow: 2px 2px #eaad20;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms ease-in-out;
  &:hover {
    background-color: #eaad2024;
    ${mobile(({
      backgroundColor: "#fff"
    }))}
  }

  &:before {
    font-family: "swiper-icons";
    font-size: 20px;
    color: #333;
  }
`;

const Container = styled.div`
  padding: 20px;
  width: 100%;
  max-width: 1666px;
  /* box-sizing: border-box; */
  ${mobile({
    width: "92vw",
    padding: 0,
  })}
`;

export default HScroll;

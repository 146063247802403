export function capitalizeFirstLetter(str) {
  return str.replace(/^\w/, (c) => c.toUpperCase());
}
export function formatString(str) {
  let words = str.toLowerCase().split(" ");
  for (let i = 0; i < words.length - 1; i++) {
    if (words[i] !== "" ) {
      words[i] = words[i][0]?.toUpperCase() + words[i].slice(1);
    }
  }
  return words.join(" ");
}

import {
  Facebook,
  Instagram,
  MailOutline,
  Phone,
  RingVolume,
  Room,
  Twitter,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile } from "../responsive";

export const Footer = () => {
  return (
    <Container>
      <Left>
        <Logo>NOAH.</Logo>
        <Desc>
          <Br />
          ORIGINAL FOOTWEAR.👟
          <Br />
          Store in Jijel : 02 Rue OASIS
          <Br />
          Brands : Nike, Adidas, Puma, New Balance, Asics, Reebok, Skechers
          <Br />
          📞 0554935389
        </Desc>
        <SocialContainer>
          <SocialIcon color="3b5999">
            <a href="https://www.facebook.com/profile.php?id=100063726504026">
              <Facebook />
            </a>
          </SocialIcon>
          <SocialIcon color="E4405f">
            <a
              href="https://www.instagram.com/noahstore_18/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </a>
          </SocialIcon>
          <SocialIcon color="55acee">
            <a href="/#">
              <Twitter />
            </a>
          </SocialIcon>
        </SocialContainer>
      </Left>

      <Center>
        <Title>Useful Links</Title>
        <List>
          <Link style={{
            textDecoration: "none",
            color: "white"
          }} to="/">
          <ListItem>Home</ListItem>
          </Link>
          <Link style={{
            textDecoration: "none",
            color: "white"
          }} to="/products/all/men">
          <ListItem>Men shop</ListItem>
          </Link>
          <Link style={{
            textDecoration: "none",
            color: "white"
          }} to="/products/all/women">
          <ListItem>women shop</ListItem>
          </Link>
          <Link style={{
            textDecoration: "none",
            color: "white"
          }} to="/products/accessories/all">
          <ListItem>Accessories</ListItem>
          </Link>
          <Link style={{
            textDecoration: "none",
            color: "white"
          }} to="/products/all/all/insold">
          <ListItem>sale</ListItem>
          </Link>
        </List>
      </Center>

      <Right>
        <Title>Contact</Title>
        <ContactItem>
          <Room style={{ marginRight: "10px" }} />2 rue des moudjahidine, Jijel,
          Algeria
        </ContactItem>
        <ContactItem>
          <Phone style={{ marginRight: "10px" }} /> 0554 93 53 89
        </ContactItem>
        <ContactItem>
          <RingVolume style={{ marginRight: "10px" }} /> 034 49 29 45
        </ContactItem>
        <ContactItem>
          <MailOutline style={{ marginRight: "10px" }} /> thenoahstore@gmail.com
        </ContactItem>
        {/* <Payment src="https://i.ibb.co/Qfvn4z6/payment.png" /> */}
      </Right>
      <Map>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2685.7080471520167!2d5.760696655689994!3d36.82179985854536!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12f261c7c5e79d4f%3A0x70cd0007aa055d2!2sNoah%20Store%20(Original%20Footwear)!5e0!3m2!1sen!2sdz!4v1679154738841!5m2!1sen!2sdz"
          width="100%"
          height="100%  "
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </Map>
    </Container>
  );
};

const Map = styled.div`
  flex: 1;
  padding: 30px;
  iframe {
    filter: grayscale(20%) invert(10%) contrast(100%);
  }
  ${mobile({
    padding: 0,
  })}
`;

const Br = styled.div`
  margin-bottom: 5px;
`;

const Container = styled.div`
  /* margin-top: 50px; */
  display: flex;
  background-color: #1c1d1e;
  color: white;
  padding: 0 30px;
  ${mobile({
    flexDirection: "column",
    paddingBottom: 20
  })}
`;
// ====== LEFT ========

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;
const Logo = styled.h1``;
const Desc = styled.div`
  margin: 20px 0;
`;
const SocialContainer = styled.div`
  display: flex;
`;
const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  background-color: #${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  a {
    text-decoration: none;
    color: white;
  }
`;
// ====== CENTER ========
const Title = styled.h3`
  margin-bottom: 30px;
`;
const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  /* display: flex; */
  /* flex-wrap: wrap; */
`;
const ListItem = styled.li`
  /* width: 50%; */
  text-transform: capitalize;
  margin-bottom: 5px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
const Center = styled.div`
  flex: 0.5;
  padding: 20px;
  ${mobile({
    display: "none",
  })}
`;
// ====== RIGHT ========
const ContactItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  padding: 20px;
  flex: 1;

  ${mobile({
    backgroundColor: "#eeeeee20",
  })}
`;

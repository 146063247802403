import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile } from "../responsive";
import { formatString } from "../helpers/textOptions";
import { BASE_URL } from "../requestMethods";
export const Product = ({ item, hscroll }) => {
  const price = item.sold.inSold ? item.sold.soldPrice : item.price;
  return (
    <Div>
      <Link
        to={`/product/${item._id}`}
        style={{
          color: "black",
          textDecoration: "none",
        }}
      >
        <Container hscroll={hscroll}>
          {item.sold.inSold ? (
            <Percenage>
              &nbsp; {Math.floor((item.sold.soldPrice / item.price - 1) * 100)}{" "}
              % &nbsp;
            </Percenage>
          ) : null}

          <Wrapper>
            <ImageContainer>
              <Img
                src={BASE_URL + "media/image/" + item.img[0]}
                alt=""
                loading="lazy"
              />
            </ImageContainer>
          </Wrapper>
        </Container>
        <Info>
          <Title>
            <p>{formatString(item.title)}</p>
          </Title>
          <Ref>{item.ref}</Ref>
          <Prices>
            <Price>DA {price}</Price>
            {item.sold.inSold && <Sold>DA {item.price} </Sold>}
          </Prices>
        </Info>
      </Link>
    </Div>
  );
};

const Percenage = styled.div`
  color: white;
  font-size: 17px;
  padding: 3px;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 50px;
  height: 30px;
  /* border-radius: 50px; */
  display: grid;
  align-items: center;
  font-weight: bold;
  background-color: red;
  ${mobile({
    padding: 0,
    fontSize: 13,
    minWidth: 30,
  })}/* z-index: 999; */
`;

const Ref = styled.p`
  margin: 5px 0;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  color: #999;
  font-size: 13px;
  text-align: left !important;
`;

const Sold = styled.p`
  color: red;
  text-decoration: line-through;
  font-size: 17px;
  ${mobile({
    fontSize: 15,
  })}
`;
const Price = styled.p`
  margin-right: 15px;
`;

const Prices = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  ${mobile({
    fontSize: 17,
  })}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Img = styled.img`
  max-width: 100%;
  transition: all 0.5s;
  z-index: 0;
  /* object-fit: contain; */
`;

const Info = styled.div`
  width: 100%;
  padding: 0 7px;
  margin-top: 10px;
  font-size: 16px;
  color: black;
`;
const Title = styled.div`
  font-weight: bold;
  text-transform: capitalize;
  text-align: left !important;
  white-space: nowrap;
  padding-right: 10px;
  p {
    width: 100%;
    text-align: left !important;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  max-height: 380px;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  ${mobile({
    marginBottom: "10px",
    maxHeight: (props) => (props.hscroll ? "300px" : "185px"),
    minHeight: (props) => (props.hscroll ? "280px" : "150px"),
  })}
`;

const ImageContainer = styled.div`
  width: 100%;
  /* height: 350px; */
  /* z-index: 2; */
`;

const Div = styled.div`
  /* margin-bottom: 20px; */
  /* border: 1px solid lightgray; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 350px;
  ${mobile({
    // maxWidth: 150,
    // flexBasis: 1,
    justifyContent: "space-between",
    // maxWidth: 150,
    // minWidth: 100
  })}
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  &:hover ${Img} {
    /* scale: 1.05; */
  }
`;

import { Link } from "react-router-dom";
import styled from "styled-components";
import { genders } from "../data";
import { mobile } from "../responsive";
const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  ${mobile({
    padding: "0",
    flexDirection: "column",
  })}
`;
export const GenderCategories = () => {
  return (
    <Wrapper>
      {genders.map((item) => (
        <CategoryItem item={item} key={item.id} />
      ))}
    </Wrapper>
  );
};
const CategoryItem = ({ item }) => {
  return (
    <Container>
      <Link to={`/products/all/${item.gender}`}>
        <Image src={item.img} />
        <Info>
          <Title>{item.title}</Title>
          <Button>Shop now</Button>
        </Info>
      </Link>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  margin: 3px;
  height: 70vh;
  position: relative;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${mobile({
    height: "30vh",
  })}
`;
const Info = styled.div`
  position: absolute;
  /* width: 100%; */
  /* height: 100%; */
  bottom: 50px;
  left: 50px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
`;
const Title = styled.h1`
  color: white;
  margin-bottom: 20px;
`;

const Button = styled.div`
  color: black;
  display: grid;
  place-content: center;
  padding: 13px 15px;
  background-color: white;
  font-weight: 600;
  box-shadow: 2px 2px #eaad20;
  transition: color 0.25s ease-out, background-color 0.25s ease-out;
  &:hover {
    background-color: #eaad20;
    color: white;
  }
  ${mobile({
    fontSize: 12,
    width: 70,
    padding: "10px 13px"
  })}

  cursor: pointer;
`;

// const Button = styled.button`
//   border: none;
//   border-radius: 3px;
//   padding: 10px;
//   min-width: 110px;
//   background-color: white;
//   color: gray;
//   font-weight: 600;
//   cursor: pointer;
//   &:hover {
//     box-shadow: 4px 4px 15px 0px rgba(255, 255, 255, 0.4);
//     -webkit-box-shadow: 4px 4px 15px 0px rgba(255, 255, 255, 0.4);
//     -moz-box-shadow: 4px 4px 15px 0px rgba(255, 255, 255, 0.4);
//   }
// `;

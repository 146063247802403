import { css } from "styled-components"

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
  }

export const mobile = (props) => {
    return css`
        @media only screen and (max-width:420px){
            ${props}
        }
    `
}


export const mobileM = (props) => {
    return css`
        @media only screen and (max-width:${size.mobileM}){
            ${props}
        }
    `
}
export const sliderItems = [
    {
        id:1,
        img:"https://images.unsplash.com/photo-1547483238-f400e65ccd56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        title: "SUMMER SALE",
        desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF NEW ARRIVALS.",
        bg: "f5fafd"
    },
    {
        id:2,
        img:"https://images.unsplash.com/photo-1511715885542-a3713331633d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
        title: "SUMMER SALE",
        desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF NEW ARRIVALS.",
        bg: "fbf0f4"
    },
    {
        id:3,
        img:"https://images.unsplash.com/photo-1648461314543-a6b1bccd3094?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1461&q=80",
        title: "VALHALLA SALE",
        desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF NEW ARRIVALS.",
        bg: "fcf1ed"
    }
]

export const categories = [
    {
      id: 1,
      img: "https://images.unsplash.com/photo-1556906781-9a412961c28c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80",
      title: "SHOES!",
      cat: "shoes"
    },
    {
      id: 2,
      img: "https://images.unsplash.com/photo-1500468756762-a401b6f17b46?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80",
      title: "CLOTHES",
      cat: "clothes"

    },
    {
      id: 3,
      img: "https://images.unsplash.com/photo-1576871337632-b9aef4c17ab9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
      title: "ACCESSORIES",
      cat: "accessories"
    },
  ];
export const genders = [
    {
      id: 1,
      img: "https://images.unsplash.com/photo-1618453292507-4959ece6429e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80",
      title: "MAN",
      gender: "men"
    },
    {
      id: 2,
      img: "https://images.unsplash.com/photo-1602751184834-947bd06e8710?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
      title: "WOMEN",
      gender: "women"

    },
    {
      id: 3,
      img: "https://images.unsplash.com/photo-1527289631404-6b929d0a126f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      title: "KIDS",
      gender: "kids"
    },
  ];


  export const brands = [
    {
      id:1,
      brand:"nike",
      img:"https://firebasestorage.googleapis.com/v0/b/noah-shop-ad163.appspot.com/o/0000000_brands%2Fnike.webp?alt=media&token=58bf3c38-8396-4a25-954b-dd72067a929a",
    },
    {
      id:2,
      brand:"adidas",
      img:"https://firebasestorage.googleapis.com/v0/b/noah-shop-ad163.appspot.com/o/0000000_brands%2Fadidas.webp?alt=media&token=ae89a21e-016d-43bc-a646-c92d3bec8d94",
    },
    {
      id:3,
      brand:"air-jordan",
      img:"https://firebasestorage.googleapis.com/v0/b/noah-shop-ad163.appspot.com/o/0000000_brands%2Fair-jordan.webp?alt=media&token=31725748-7401-4942-a99d-f1e9003290ae",
    },
    {
      id:4,
      brand:"asics",
      img:"https://firebasestorage.googleapis.com/v0/b/noah-shop-ad163.appspot.com/o/0000000_brands%2Fasics.webp?alt=media&token=9895bc6c-a183-4d2f-84df-8381bb3fe35a",
    },
    {
      id:5,
      brand:"champion",
      img:"https://firebasestorage.googleapis.com/v0/b/noah-shop-ad163.appspot.com/o/0000000_brands%2Fchampion.webp?alt=media&token=a6812f9f-f6ee-44d7-9992-3b53711248c7",
    },
    {
      id:6,
      brand:"converse",
      img:"https://firebasestorage.googleapis.com/v0/b/noah-shop-ad163.appspot.com/o/0000000_brands%2Fconverse.webp?alt=media&token=19f4d9ac-c679-438e-afe2-ecfe99f529ac",
    },
    {
      id:7,
      brand:"new-balance",
      img:"https://firebasestorage.googleapis.com/v0/b/noah-shop-ad163.appspot.com/o/0000000_brands%2Fnew-balance.webp?alt=media&token=90a91df9-b99e-4a1d-8d58-f8705ce03f0c",
    },
    {
      id:8,
      brand:"puma",
      img:"https://firebasestorage.googleapis.com/v0/b/noah-shop-ad163.appspot.com/o/0000000_brands%2Fpuma.webp?alt=media&token=a779a37c-efc7-42ae-8e70-2298997a141d",
    },
    {
      id:9,
      brand:"reebok",
      img:"https://firebasestorage.googleapis.com/v0/b/noah-shop-ad163.appspot.com/o/0000000_brands%2Freebok.webp?alt=media&token=2d1f0006-8b55-41ab-9de8-7738a7da8ec8",
    },
    {
      id:10,
      brand:"skechers",
      img:"https://firebasestorage.googleapis.com/v0/b/noah-shop-ad163.appspot.com/o/0000000_brands%2Fskechers2.jpg?alt=media&token=638e4724-95c9-4cc2-8216-b24b29a6a384",
    },
    {
      id:11,
      brand:"under-armour",
      img:"https://firebasestorage.googleapis.com/v0/b/noah-shop-ad163.appspot.com/o/0000000_brands%2Funder-armour.webp?alt=media&token=2a047ba0-142f-44ea-8c95-1b5f5b910392",
    },
    {
      id:12,
      brand:"crocks",
      img:"https://firebasestorage.googleapis.com/v0/b/noah-shop-ad163.appspot.com/o/0000000_brands%2Fcrocks.png?alt=media&token=563667c4-b144-46c1-a0a0-16fd5ccceadf",
    },
    // {
    //   id:1,
    //   brand:"",
    //   img:"",
    // },
  ]

export const popularProducts = [
    {
      id:1,
      img:"https://d3o2e4jr3mxnm3.cloudfront.net/Mens-Jake-Guitar-Vintage-Crusher-Tee_68382_1_lg.png",
    },
    {
      id:2,
      img:"https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,b_rgb:f5f5f5/0dd2c1cb-4a1c-42e1-950b-bddc4d16ee84/solo-swoosh-mens-fleece-pants-90h6N2.png",
    },
    {
      id:3,
      img:"https://www.prada.com/content/dam/pradanux_products/U/UCS/UCS319/1YOTF010O/UCS319_1YOT_F010O_S_182_SLF.png",
    },
    {
      id:4,
      img:"https://www.burdastyle.com/pub/media/catalog/product/cache/7bd3727382ce0a860b68816435d76e26/107/BUS-PAT-BURTE-1320516/1170x1470_BS_2016_05_132_front.png",
    },
    {
      id:5,
      img:"https://images.ctfassets.net/5gvckmvm9289/3BlDoZxSSjqAvv1jBJP7TH/65f9a95484117730ace42abf64e89572/Noissue-x-Creatsy-Tote-Bag-Mockup-Bundle-_4_-2.png",
    },
    {
      id:6,
      img:"https://d3o2e4jr3mxnm3.cloudfront.net/Rocket-Vintage-Chill-Cap_66374_1_lg.png",
    },
    {
      id:7,
      img:"https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,b_rgb:f5f5f5/6eaab6f5-8ba8-4061-a69b-e82c227eaba2/sportswear-club-big-kids-girls-french-terry-pants-vxBsXM.png",
    },
    {
      id:8,
      img:"https://www.pngarts.com/files/3/Women-Jacket-PNG-High-Quality-Image.png",
    }
]
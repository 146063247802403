import { createSlice } from '@reduxjs/toolkit'

const currentUser = localStorage.getItem("currentUser") === null ? null : JSON.parse(localStorage.getItem("currentUser"))
const wishList = localStorage.getItem("wishList") === null ? [] : JSON.parse(localStorage.getItem("wishList"))

const userSlice = createSlice({
    name: "user",
    initialState: {
        currentUser,
        wishList,
        isFetching: false,
        error: false,
    },
    reducers: {
        loginStart: (state) => {
            state.isFetching = true
        },
        loginSuccess: (state, action) => {
            state.isFetching = false
            state.currentUser = action.payload
            state.loginFailure = false
        },
        loginFailure: (state) => {
            state.isFetching = false
            state.loginFailure = true
        },
        addToWishList: (state, action) => {
            state.wishList.push(action.payload)
        }
    }
})

export const { loginStart, loginSuccess, loginFailure } = userSlice.actions
export default userSlice.reducer
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { brands } from "../data";

import "./swiper.css";
import { mobile } from "../responsive";

const brandsFooter = () => {
  return (
    <Container>
      <Swiper
        breakpoints={{
          360: {
            slidesPerView: 4,
          },
          789: {
            slidesPerView: 4,
            spaceBetween: 10,
          },

          1168: {
            slidesPerView: 8,
            spaceBetween: 10,
          },
          1650: {
            slidesPerView: 8,
            spaceBetween: 10,
          },
          1950: {
            slidesPerView: 8,
            spaceBetween: 30,
          },
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        // spaceBetween={30}
        loop={true}
        pagination={false}
        navigation={false}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        {brands.map((item, index) => (
          <SwiperSlide key={index}>
            <Brand>
              <Img src={item.img} alt={item.brand} />
            </Brand>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

const Img = styled.img`
  height: 70%;
  width: 100%;
`;
const Brand = styled.div`
  height: 80px;
  max-width: 140px;
  ${mobile({
    height: 50,
  })}
`;

const Container = styled.div`
  width: 100%;
`;

export default brandsFooter;

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import styled from "styled-components";
import noahLogo from "../../assets/LOGO.png";
import "./checkout.css";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { addOrder } from "../../redux/apiCalls";
import { useEffect } from "react";
import { BASE_URL, publicRequest, userRequest } from "../../requestMethods";
import { mobile } from "../../responsive";
import LoadingPage from "../../components/LoadingPage";
import { Helmet } from "react-helmet";
import GTag from "../../utils/GTag";
import MetaPixel from "../../utils/metaPixel";

export default function Checkout() {
  const dispatch = useDispatch();
  const card = useSelector((state) => state.cart);
  const order = useSelector((state) => state.order);
  const [deliveryOptions, setDeliveryOptions] = useState([{}]);
  const [selectecWilaya, setSelectecWilaya] = useState({});
  const [loading, setLoading] = useState(true);

  const [inputs, setInputs] = useState({
    sold: "",
    addComment: "",
    email: "",
    firstname: "",
    lastname: "",
    useraddress: "",
    userphone: "",
    usertown: "",
    town: "",
  });
  const [products, setProducts] = useState([]);
  const [total] = useState(card.total);
  const [sold, setSold] = useState();
  const [discount, setDiscount] = useState(0);
  const [couponErr, setCouponErr] = useState(0);
  const [deliveryOption, setDeliveryOption] = useState("relayPointPrice");

  const [isError, setIsError] = useState(false);
  const [couponFound, setCouponFound] = useState(false);

  const dzPhoneNumberRegex = /^(\+?213|0)(5|6|7)\d{8}$/;

  const numberPhoneValidationError = Boolean(
    inputs.userphone.length > 0 && !dzPhoneNumberRegex.test(inputs.userphone)
  );

  useEffect(() => {
    const productsArr = [];
    card.products?.map((product) =>
      productsArr.push({
        productId: product._id,
        quantity: product.quantity,
        size: product.size,
        img: product?.img,
      })
    );
    setProducts(productsArr);
  }, [card.products]);

  const redirectHome = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    const getDeliveryOptions = async () => {
      // document.title = 'Noah Store Checkout';
      setLoading(true);
      try {
        const res = await userRequest.get("/delivery");
        setLoading(false);
        setDeliveryOptions(res.data.wilayas);
        const firstWilaya = res.data.wilayas[0];
        setInputs((prev) => ({
          ...prev,
          wilaya: firstWilaya.wilaya,
        }));
        setSelectecWilaya({
          wilaya: firstWilaya.wilaya,
          homePrice: firstWilaya.homePrice,
          relayPointPrice: firstWilaya.relayPointPrice,
        });
      } catch (err) {
        // console.log("err geting delivery options");
        redirectHome();
      }
    };
    getDeliveryOptions();
  }, []);

  function calculateDiscount(totalPrice, percentage) {
    const discount = (percentage / 100) * totalPrice;
    return discount;
  }

  function calculateTotal(subTotal, discount, wilayaDelivery) {
    if (deliveryOption === "relayPointPrice" && subTotal >= 10_000)
      return subTotal - discount;

    return subTotal + parseInt(wilayaDelivery) - discount;
  }

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleDeliveryChange = (e) => {
    setDeliveryOption(e.target.value);
  };

  const handleWilayaChange = (e) => {
    const value = e.target.value;
    setInputs((prev) => ({ ...prev, wilaya: value }));
    const wilaya = deliveryOptions.find((item) => item.wilaya === value);
    setSelectecWilaya(wilaya);
  };

  const addCoupon = async (coupon) => {
    // dispatch(addOrderStart())
    try {
      const res = await publicRequest.get(`/coupons/find/${coupon}`);
      if (res.data === null) {
        // console.log("emmpty res");
        const { coupon, ...others } = inputs;
        setInputs(others);
        setSold("");

        // setInputs(prev => ({...prev, coupon: ""}))
        setDiscount(0);
        return setCouponErr(1);
      } else {
        setSold(res.data);
        setInputs((prev) => ({ ...prev, coupon: res.data._id }));
        setCouponFound(true);
        setDiscount(
          Math.round(calculateDiscount(total, res.data.couponDiscount))
        );
        return setCouponErr(0);
      }

      // dispatch(addOrderSuccess(res))
    } catch (err) {
      setCouponErr(1);
      // dispatch(addOrderFailure())
    }
  };

  const handleCouponClick = (e) => {
    e.preventDefault();
    // console.log("sold button clicked");
    addCoupon(inputs.sold);
  };

  const handleClick = (e) => {
    e.preventDefault();
    if(numberPhoneValidationError) return
    const { sold, ...others } = inputs;
    addOrder(dispatch, { ...others, products, delivery: deliveryOption });
    // console.log(order.isFetching, order.error);
    if (!order.error) {
      setIsError(true);
    }
  };
  useEffect(() => {
    if (products.length > 0) {
      GTag();
    }
  }, [products]);

  return (
    <App>
      <MetaPixel eventName={"checkout page"} />

      <Helmet>
        <title>Checkout Noah store</title>
      </Helmet>
      <Container>
        {loading ? (
          <LoadingPage />
        ) : (
          <>
            <Main>
              <MainHeader>
                <Logo href="/">
                  <LogoImg alt="Noah" src={noahLogo} />
                </Logo>
                <PaymentSeparator>
                  <SeparatorContent>
                    ENTER YOUR INFORMATIONS HERE
                  </SeparatorContent>
                </PaymentSeparator>
              </MainHeader>
              <MainContent>
                <Section>
                  <SectionTitle>Contact information</SectionTitle>
                  <InputField>
                    <Input
                      onChange={handleChange}
                      name="email"
                      className=""
                      type="email"
                      autocomplete="off"
                    />
                    <Label inputVal={inputs.email}>Email</Label>
                  </InputField>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Email me with news and offers"
                  />
                </Section>
                <Section>
                  <SectionTitle>Shipping address</SectionTitle>
                  {isError && (
                    <>
                      <GhostDiv></GhostDiv>{" "}
                      <p
                        style={{
                          color: "red",
                          letterSpacing: 1,
                        }}
                      >
                        {" "}
                        <b> please fill all spaces!</b>
                      </p>
                    </>
                  )}
                  <TwoInputs>
                    <InputField>
                      <Input
                        onChange={handleChange}
                        name="firstname"
                        className=""
                        type="text"
                        autocomplete="off"
                      />
                      <Label inputVal={inputs.firstname}>First name *</Label>
                    </InputField>
                    <InputField>
                      <Input
                        onChange={handleChange}
                        name="lastname"
                        className=""
                        type="text"
                        autocomplete="off"
                      />
                      <Label inputVal={inputs.lastname}>Last name *</Label>
                    </InputField>
                  </TwoInputs>
                  <InputField>
                    <Input
                      onChange={handleChange}
                      name="useraddress"
                      type="text"
                    />
                    <Label inputVal={inputs.useraddress}>Address *</Label>
                  </InputField>
                  <InputField style={{ margin: "20px 0" }}>
                    <Label className="selectInput">Wilaya *</Label>
                    <Select
                      onChange={handleWilayaChange}
                      name="wilaya"
                      id=""
                      value={inputs.wilaya}
                    >
                      {deliveryOptions.map((item, index) => (
                        <Option value={item.wilaya} key={index}>
                          {item.wilaya}
                        </Option>
                      ))}
                    </Select>
                  </InputField>
                  {/* <TwoInputs>
                    <InputField>
                      <Input
                        onChange={handleChange}
                        name="usertown"
                        type="text"
                        autocomplete="off"
                        inputVal={inputs.town}
                      />
                      <Label>Town*</Label>
                    </InputField>
                  </TwoInputs> */}
                  <InputField>
                    <Input
                      onChange={handleChange}
                      name="userphone"
                      type="number"
                      autocomplete="off"
                      error={numberPhoneValidationError}
                    />
                    <Label inputVal={inputs.userphone}>Phone *</Label>
                  </InputField>
                  {numberPhoneValidationError && (
                    <p style={{ color: "red", fontSize: 14 }}>
                      Please enter a valid Algerian phone number
                    </p>
                  )}

                  <InputField>
                    <Label
                      inputVal={inputs.addComment}
                      className=""
                      type="text"
                      autocomplete="off"
                    >
                      {" "}
                      Additional commnets
                    </Label>
                    <Textarea
                      onChange={handleChange}
                      contentEditable
                      name="addComment"
                    ></Textarea>
                  </InputField>
                </Section>
                <TwoInputs submit>
                  <GhostDiv></GhostDiv>
                  <SubmitButton onClick={handleClick}>order</SubmitButton>
                  <GhostDiv></GhostDiv>
                  <a href="/">continue shopping?</a>
                </TwoInputs>
              </MainContent>
            </Main>
            <Sidebar>
              <SidebarHeader>
                <SidebarContent>
                  <PaymentSeparator mobile>
                    <SeparatorContent>ABOUT YOUR ORDER</SeparatorContent>
                  </PaymentSeparator>
                  <SidebarSection>
                    <Products>
                      {card.products?.map((product, index) => (
                        <Product key={index}>
                          <ProductImg>
                            <ProductQuantity>
                              {product.quantity}
                            </ProductQuantity>
                            <Img
                              src={BASE_URL + "media/image/" + product.img}
                            />
                          </ProductImg>
                          <Info>
                            <ProductTitle>{product.title}</ProductTitle>
                            <ProductSize>{product.size}</ProductSize>
                          </Info>
                          <ProductPrice>
                            DA{" "}
                            {product.sold?.inSold
                              ? product.sold?.soldPrice
                              : product.price}
                          </ProductPrice>
                        </Product>
                      ))}
                    </Products>
                  </SidebarSection>

                  <SidebarSection>
                    <CoponSection>
                      <InputField className="sold-inputfield">
                        <Input
                          name="sold"
                          onChange={handleChange}
                          type="text"
                          autocomplete="off"
                        />
                        <Label inputVal={inputs.sold}>discount code</Label>
                      </InputField>
                      <CoponButton
                        onClick={handleCouponClick}
                        isSold={inputs.sold}
                        disabled={inputs.sold ? false : true}
                      >
                        apply
                      </CoponButton>
                    </CoponSection>
                    {couponErr === 1 && (
                      <p style={{ color: "red" }}>Coupon Invalid</p>
                    )}
                    {couponFound && couponErr !== 1 && (
                      <p style={{ color: "green" }}>
                        Coupon :{" "}
                        <b>
                          {sold.title} -{sold.couponDiscount}%
                        </b>
                      </p>
                    )}
                  </SidebarSection>
                  <SidebarSection>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: 10,
                      }}
                    >
                      <FlexTitle>Subtotal</FlexTitle>
                      <FlexNumber>DA {card.total}.00</FlexNumber>
                    </div>

                    {sold && inputs.sold && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "green",
                          paddingBottom: 10,
                        }}
                      >
                        <FlexTitle>Discount</FlexTitle>
                        <FlexNumber>DA -{discount}.00</FlexNumber>
                      </div>
                    )}
                    <FormControl component="fieldset">
                      {/* <FormLabel component="legend">Gender</FormLabel> */}
                      <RadioGroup
                        aria-label="deliveryOption"
                        name="deliveryOption"
                        value={deliveryOption}
                        onChange={handleDeliveryChange}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FormControlLabel
                            color="default"
                            value="homePrice"
                            control={<Radio color="default" size="small" />}
                            // label="Female"
                          />
                          <FlexTitleDeliver>
                            Home delivery:{" "}
                            <div>DA {selectecWilaya.homePrice}</div>
                          </FlexTitleDeliver>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FormControlLabel
                            color="default"
                            value="relayPointPrice"
                            control={<Radio color="default" size="small" />}
                            // label="Male"
                          />
                          <FlexTitleDeliver>
                            Anderson relay point:{" "}
                            <div>
                              DA{" "}
                              {card.total >= 10_000 ? (
                                <b>0,00</b>
                              ) : (
                                selectecWilaya.relayPointPrice
                              )}
                            </div>
                          </FlexTitleDeliver>
                        </div>
                      </RadioGroup>
                    </FormControl>
                    {card.total >= 10_000 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingBottom: 10,
                        }}
                      >
                        <p
                          style={{
                            marginBlock: "30px 0px",
                            marginInline: "auto",
                            fontWeight: "bold",
                            color: "green",
                            textAlign: "center",
                          }}
                        >
                          🎊 ✨ FREE RELAY POINT DELIVERY ORDER ✨ 🎊
                        </p>
                      </div>
                    )}
                  </SidebarSection>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 20px",
                    }}
                  >
                    <TotalTatle>Total</TotalTatle>
                    <TotalPrice>
                      DA{" "}
                      <b>
                        {calculateTotal(
                          total,
                          discount,
                          selectecWilaya[deliveryOption]
                        )}
                        .00
                      </b>
                    </TotalPrice>
                  </div>
                </SidebarContent>
              </SidebarHeader>
              <TwoInputs submit submitMobile>
                {/* <GhostDiv></GhostDiv> */}
                <SubmitButton onClick={handleClick}>order</SubmitButton>
                {isError && (
                  <p
                    style={{
                      color: "red",
                      letterSpacing: 1,
                    }}
                  >
                    please fill all spaces
                  </p>
                )}
                <a href="/">continue shopping?</a>
              </TwoInputs>
            </Sidebar>
          </>
        )}
        {order.isFetching && <LoadingPage />}
      </Container>
    </App>
  );
}

const GhostDiv = styled.div`
  ${mobile({
    display: "none",
  })}
`;
const TotalTatle = styled.p`
  font-size: 20px;
`;

const TotalPrice = styled.p`
  font-size: 25px;
  font-weight: 300;
  ${mobile({
    fontSize: 20,
  })}
`;

const FlexTitleDeliver = styled.div`
  width: 100%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
`;

const FlexTitle = styled.p`
  font-weight: 500;
`;
const FlexNumber = styled.p``;

const CoponSection = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;
  ${mobile({
    gap: 10,
    paddingLeft: 0,
  })}
`;

const CoponButton = styled.button`
  grid-column-start: 5;
  grid-column-end: 6;
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  padding: 13px 18px;
  font-weight: 700;
  border: 2px solid ${(props) => (props.isSold === "" ? "grey" : "#212a2f")};
  border-radius: 5px;
  color: #fff;
  background-color: ${(props) => (props.isSold === "" ? "grey" : "#212a2f")};
  transition: color 0.25s ease-out, background-color 0.25s ease-out,
    border-color 0.25s ease-out;
  appearance: none;
  cursor: ${(props) => (props.isSold === "" ? "default" : "pointer")};
  &:hover {
    background-color: ${(props) => (props.isSold === "" ? "grey" : "#fff")};
    color: ${(props) => (props.isSold === "" ? "white" : "#212a2f")};
    font-weight: 700;
  }
`;

const SidebarSection = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  border-bottom-width: 70%;
  border-bottom: 1px solid #e6e6e6;
  ${mobile({
    padding: 10,
  })}
`;

const ProductQuantity = styled.div`
  position: absolute;
  top: -8px;
  left: -8px;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background-color: gray;
  color: white;
  display: grid;
  place-content: center;
`;

const Img = styled.img`
  width: 100%;
  object-fit: contain;
  box-sizing: border-box;
  border: 1px solid grey;
  border-radius: 9px;
`;

const Products = styled.div`
  width: 90%;
  ${mobile({
    width: "100%",
    marginTop: "30px",
  })}
`;
const Product = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  color: #323232;
  margin-bottom: 7px;
`;

const ProductImg = styled.div`
  width: 90px;
  /* height: 4.6em; */
  border-radius: 8px;
  background: transparent;
  margin-right: 10px;
  position: relative;
`;
const ProductPrice = styled.div`
  width: 110px;
  display: flex;
  justify-content: right;
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
`;
const ProductTitle = styled.div`
  font-size: 14px;
  line-height: 1.2;
`;
const ProductSize = styled.div`
  font-size: 0.8571428571em;
  font-weight: 300;
  color: #717171;
  text-transform: uppercase;
`;

const SidebarContent = styled.div``;

const App = styled.div`
  line-height: 1.5;
  letter-spacing: 0.5px;
  display: grid;
  place-items: center;
`;
const Sidebar = styled.div`
  /* background-color: #e6e6e6; */
  width: 38%;
  height: 100%;
  padding-left: 4%;
  padding-top: 4em;
  background-position: left top;
  float: right;
  position: relative;
  ${mobile({
    width: "100%",
    paddingLeft: 0,
    paddingTop: 0,
  })}
`;

const SidebarHeader = styled.div``;

//* ================ Main =================
const SubmitButton = styled.button`
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  padding: 13px 18px;
  font-weight: 700;
  border: 2px solid #212a2f;
  border-radius: 5px;
  color: #fff;
  background-color: #212a2f;
  transition: color 0.25s ease-out, background-color 0.25s ease-out,
    border-color 0.25s ease-out;
  appearance: none;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    color: #212a2f;
    font-weight: 700;
  }
`;

const Textarea = styled.input`
  margin: 20px 0;
  background: transparent;
  border: 1px grey solid;
  background-clip: padding-box;
  border-radius: 5px;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  min-width: 100%;
  max-width: 100%;
  min-height: 7em;
  overflow: auto;
  padding: 0.8em 0.7857142857em;
  padding-top: 2.5em;
  word-break: normal;
  line-height: inherit;

  &:focus {
    outline: 1px solid black;
  }
`;

const Option = styled.option`
  padding: 0.8em 0.7857142857em;
  font-size: 16px;
`;

const Select = styled.select`
  margin: 0;
  background: transparent;
  border: 1px grey solid;
  background-clip: padding-box;
  border-radius: 5px;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;
  padding: 1em 0.7857142857em;
  padding-top: 1.2em;
  word-break: normal;
  line-height: inherit;

  &:focus {
    outline: 1px solid black;
  }
`;

const TwoInputs = styled.div`
  display: ${(props) => (props.submitMobile ? "none" : "grid")};
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 20px;
  margin: 20px 0;
  a {
    color: black;
    text-align: center;
    letter-spacing: 1.1px;
    font-size: 16px;
    font-weight: bold;
  }
  @media only screen and (max-width: 420px) {
    display: ${(props) => (props.submit ? "none" : "grid")};
    display: ${(props) => (props.submitMobile ? "grid !important" : null)};
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Section = styled.div`
  /* padding-top: 40px; */
  /* padding-bottom: 30px; */
`;

const Input = styled.input`
  background-color: transparent;
  z-index: 999;
  margin: 0;
  appearance: none;
  background: transparent;
  border: 1px grey solid;
  background-clip: padding-box;
  border-radius: 5px;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;
  padding: 0.8em 0.7857142857em;
  padding-top: 1.2em;
  word-break: normal;
  line-height: inherit;
  border-color: ${(props) => props.error && 'red'};

  &:focus {
    outline: 1px solid black;
  }
`;

const Label = styled.label`
  pointer-events: none;
  position: absolute;
  left: ${(props) => (props.inputVal === "" ? "16px" : "8px")};
  top: ${(props) => (props.inputVal === "" ? "17px" : "4px")};
  font-size: ${(props) => (props.inputVal === "" ? "" : "13px")};
  /* left: 16px; */
  /* top: 17px; */
  color: #333;
  transition: all 0.1s;

  ${Input}:focus ~ & {
    top: 4px;
    font-size: 13px;
  }
`;

const InputField = styled.div`
  position: relative;
`;
const SectionTitle = styled.div`
  margin: 20px 0;
  font-size: 1.2857142857em;
  line-height: 1.3em;
  font-weight: 600;
`;

const PaymentSeparator = styled.div`
  display: ${(props) => (props.mobile ? "none" : "flex")};
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 0.8571428571em;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  color: #737373;
  margin-top: 2.5em;
  &::after,
  &::before {
    content: "";
    display: inline-block;
    height: 1px;
    background-color: #e6e6e6;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  ${mobile({
    display: "flex",
  })}
`;

const SeparatorContent = styled.p`
  font-size: 0.8571428571em;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  color: #737373;
  padding-inline: 20px;
`;

const Container = styled.div`
  /* height: 100%; */
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 5%;
  width: 90%;
  max-width: 78.5714285714em;
  ${mobile({
    flexDirection: "column",
  })}
`;
const Main = styled.div`
  width: 52%;
  padding-right: 6%;
  float: left;
  padding-top: 2em;
  text-align: left;
  ${mobile({
    width: "100%",
    paddingRight: 0,
    paddingTop: 20,
  })}
`;
const MainHeader = styled.div`
  padding-bottom: 2em;
  ${mobile({
    paddingBottom: "0.8em",
  })}
`;
const Logo = styled.a`
  font-weight: 700;
  text-decoration: none;
  color: #212a2f;
  margin: 0;
  padding: 0;
  ${mobile({
    display: "block",
    width: "100%",
    textAlign: "center",
  })}
`;
const LogoImg = styled.img`
  border: 0;
  max-width: 100px;
`;
const MainContent = styled.div``;

import { createSlice } from '@reduxjs/toolkit'

const products = JSON.parse(localStorage.getItem("products")) || []
const quantity = products.length
const total = parseInt(localStorage.getItem("total")) || 0

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        products: products,
        quantity: quantity,
        total: total,
    },
    reducers: {
        addProduct: (state, action) => {
            state.quantity += 1
            state.products.push(action.payload)
            state.total += (action.payload.sold.inSold ? action.payload.sold.soldPrice : action.payload.price)  * action.payload.quantity

            localStorage.setItem("products", JSON.stringify(state.products))
            // localStorage.setItem("quantity", JSON.stringify(state.quantity))
            localStorage.setItem("total", state.total)
        },
        deleteProduct: (state, action) => {
            state.quantity -= 1
            state.products.splice(
                state.products.findIndex((item) => item._id === action.payload._id),
                1
              );
            state.total -= (action.payload.sold.inSold ? action.payload.sold.soldPrice : action.payload.price)  * action.payload.quantity

            localStorage.setItem("products", JSON.stringify(state.products))
            // localStorage.setItem("quantity", JSON.stringify(state.quantity))
            localStorage.setItem("total", state.total)
        },
        deleteAllProducts : (state) => {
            state.products= []
            state.quantity = 0
            state.total = 0
            localStorage.setItem("products", JSON.stringify(state.products))
            localStorage.setItem("total", state.total)
        }
    }
})

export const { addProduct,deleteProduct, deleteAllProducts } = cartSlice.actions
export default cartSlice.reducer
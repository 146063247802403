import { loginFailure, loginStart, loginSuccess } from "./userRedux";
import { publicRequest } from "../requestMethods";
import { addOrderFailure, addOrderStart, addOrderSuccess } from "./orderRedux";
import { deleteAllProducts } from "./cartRedux";
import { getProductFailure, getProductStart, getProductSuccess } from "./productRedux";


export const login = async (dispatch, user) => {
    dispatch(loginStart());
    try {
        const res = await publicRequest.post("/auth/login", user);
        localStorage.setItem("currentUser", JSON.stringify(res.data))
        dispatch(loginSuccess(res.data));
    } catch (err) {
        dispatch(loginFailure());
    }
};

export const addOrder = async (dispatch, oreder) => {
    dispatch(addOrderStart())
    try {
        const res = await publicRequest.post("/orders", oreder)
        dispatch(addOrderSuccess(res))
        dispatch(deleteAllProducts())
    } catch (err) {
        dispatch(addOrderFailure())
    }
}

export const getProducts = async (dispatch) => {
    dispatch(getProductStart());
    try {
      const res = await publicRequest.get("/products");
      dispatch(getProductSuccess(res.data));
    } catch (err) {
      dispatch(getProductFailure());
    }
  };
  
import { LocalShipping, Phone } from "@material-ui/icons";
import styled from "styled-components";
import { mobile } from "../responsive";

const Announcement = () => {
  return (
    <Container>
      <Element mobile style={{ textAlign: "left" }}>
        <Content>
          <LocalShipping style={{ marginRight: "10px", fontSize: 16 }} />
          Delivery available 58w
        </Content>
      </Element>
      <Element style={{flex: 2}}>
        WELCOME OFFER: {' '}
        <p
          style={{ color: "#eaad20", display: "inline-block", fontWeight: 600 }}
        >
          FREE DELIVERY
        </p>
         {' '}ORDERS ABOVE <b>10 000 DA.</b>
      </Element>
      <Element mobile>
        <Content style={{ justifyContent: "right", marginRight: 20 }}>
          <Phone style={{ marginRight: "10px", fontSize: 16 }} /> 0554 93 53 89
        </Content>
      </Element>
    </Container>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Element = styled.div`
  flex: 1;
  display: "flex";
  justify-content: center;
  align-items: center;
  letter-spacing: 1.5px;
  ${mobile({
    display: (props) => props.mobile && "none",
  })}
`;

const Container = styled.div`
  height: 30px;
  background-color: #212a2f;
  color: white;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  padding-inline: 40px;
  ${mobile({
    fontSize: "14px",
    height: "25px",
    padding: "5px 0",
  })}
`;

export default Announcement;

import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { Product } from "./Product";
import { useDispatch, useSelector } from "react-redux";
import { getProductSuccess } from "../redux/productRedux";
import { publicRequest } from "../requestMethods";
import { useLocation } from "react-router-dom";
import { areArraysDifferent } from "../utils/checkArrayEquality";
import { mobile } from "../responsive";

export const Products = () => {
  const [products, setProducts] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  // eslint-disable-next-line
  const [fetchingErr, setFetchingErr] = useState(false);
  const [prevParams, setPrevParams] = useState({ gender: [] });

  const dispatch = useDispatch();

  const filter = useSelector((state) => state.filter);

  const location = useLocation();
  const path = location.pathname;
  const category = path.split("/")[2] || "all";
  const gender = path.split("/")[3]?.split("-") || ["all"];
  const sold = path.split("/")[4] || "all";

  const params = {
    gender,
    category,
    sold,
    ...(filter.colorsFiltered.length && { color: filter.colorsFiltered }),
    ...(filter.sizesFiltered.length && { size: filter.sizesFiltered }),
    ...(filter.brandsFiltered.length && { brand: filter.brandsFiltered }),
    ...(filter.sort !== "" && { sort: filter.sort }),
  };

  //? noahXXX.com/products/ {category}     /      {gender}       /     {inslod}   /    {souCategory}
  //*                      category || "all"   /  gender || "all"   / "insold"||"all" / souCat || "all" || null

  const getProducts = async () => {
    setIsFetching(true);

    //*check if category gender and sold are changed so we update dispatched products
    const categoryChanged = prevParams.category !== params.category;
    const genderChanged = areArraysDifferent(prevParams.gender, params.gender);
    const soldChanged = prevParams.sold !== params.sold;

    try {
      const res = await publicRequest.get("/products", { params });
      //*here we dispatch new products
      if (categoryChanged || soldChanged || genderChanged) {
        dispatch(getProductSuccess(res.data));
      }
      setProducts(res.data);
      setIsFetching(false);
      //*update prev Params
      setPrevParams({ category, gender, sold });
    } catch (err) {
      setFetchingErr(true);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line
  }, [location, filter]);

  // useEffect(() => {
  //   const getProducts = async () => {
  //     try {
  //       const res = await axios.get(`${url}products`, { params });
  //       const products = res.data;
  //       setProducts(products);
  //       distinctSizes(products)
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   getProducts();
  //   // console.log("useEffect 1");
  // }, [params,distinctSizes]);

  // useEffect(() => {
  //   category &&
  //     seFilteredProducts(
  //       products.filter((item) =>
  //         Object.entries(filters).every(([key, value]) =>
  //           item[key].includes(value)
  //         )
  //       )
  //     );
  //   console.log("useEffect 2");
  // }, [products, category, filters]);

  // useEffect(() => {
  //   if (sort === "newest") {
  //     setProducts((prev) =>
  //       [...prev].sort((a, b) => a.createdAt - b.createdAt)
  //     );
  //   } else if (sort === "low-high") {
  //     setProducts((prev) => [...prev].sort((a, b) => a.price - b.price));
  //   } else {
  //     setProducts((prev) => [...prev].sort((a, b) => b.price - a.price));
  //   }
  // }, [sort]);

  return (
    <ProductContainer>
      <ProductGridContainer>
        {isFetching ? (
          <NoProducts>
            <h1>....</h1>
          </NoProducts>
        ) : products.length > 0 ? (
          products.map((item, index) => <Product item={item} key={index} />)
        ) : (
          products.length === 0 && (
            <NoProducts>
              <h1>Oops no products :(</h1>
            </NoProducts>
          )
        )}
      </ProductGridContainer>
    </ProductContainer>
  );
};

const NoProducts = styled.div`
  h1 {
    font-weight: 300;
  }
  padding: 70px;
`;

const ProductContainer = styled.div`
  display: grid;
  place-content: center;
  width: 100%;
  ${mobile({
    width: "100vw",
  })}
  margin-top: 10px;
  margin-bottom: 20px;
`;

const ProductGridContainer = styled.div`
  /* display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  grid-template-columns: repeat(2, 50.00%); */
  /* display: flex;
  flex-flow: row wrap;
  gap: 20px;
  justify-content: center;
  align-items: end; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  ${mobile({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
    gap: "10px",
    overflowX: "hidden"
  })}
`;

import { Badge } from "@material-ui/core";
import {
  Close,
  ArrowForwardIosOutlined,
  Menu,
  Search,
  ShoppingCartOutlined,
  ArrowBackIosOutlined,
} from "@material-ui/icons";
import styled from "styled-components";
import { mobile } from "../responsive";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CartSidebar } from "./CartSidebar";
import { useState } from "react";
import logo from "../assets/LOGO2.png";
import { BASE_URL, publicRequest } from "../requestMethods";
import { formatString } from "../helpers/textOptions";

const Navbar = () => {
  const quantity = useSelector((state) => state.cart.quantity);
  // console.log(quantity);
  const [openSide, setOpenSide] = useState(false);
  const [products, setProducts] = useState([]);
  const [activeSearch, setActiveSearch] = useState(false);
  const [showSearchMobile, setShowSearchMobile] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [slideMobileMenu, setSlideMobileMenu] = useState(false);
  const [slideSelected, setSlideSelected] = useState("");
  const [soldSelected, setSoldSelected] = useState(false);

  const handleSideClick = () => {
    setOpenSide(!openSide);
  };
  const getProducts = async (value) => {
    try {
      const res = await publicRequest.get(`/products/search/${value}`);
      setProducts(res.data);
    } catch (err) {}
  };

  let timer = null;
  const handleSearchChange = (e) => {
    // clear any existing timer
    clearTimeout(timer);

    const value = e.target.value;
    if (value === "") {
      // console.log("value is empty");
      setActiveSearch(false);
      return;
    }
    // start a new timer to delay the getProducts function call
    timer = setTimeout(() => {
      getProducts(value);
      setActiveSearch(true);
    }, 1000);
  };

  const handleMenuItemClick = (gender, sold) => {
    setSlideSelected(gender);
    setSlideMobileMenu(true);
    setSoldSelected(sold || false);
  };

  const closeMobileMenu = () => {
    setShowMobileMenu(false);
    setTimeout(() => setSlideMobileMenu(false), 1000);
  };

  return (
    <Container>
      <Wrapper>
        <CartSidebar
          openSide={openSide}
          setOpenSide={setOpenSide}
          handleSideClick={handleSideClick}
        />

        <Left>
          <ResponsiveMenu isSearchActive={showSearchMobile}>
            <Menu
              style={{ fontSize: "30px" }}
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            />
          </ResponsiveMenu>
          {/* <Language>ENG</Language> */}
          <DesktopMenu>
            <ManLi>
              <ManLink>
                <DesktopMenuItem href="/products/all/men">
                  <b>men</b>
                </DesktopMenuItem>
              </ManLink>
              <MegaBox>
                <MegContent>
                  <Row>
                    <header>shoes</header>
                    <ul className="mega-links">
                      <li>
                        <a href="/products/shoes/men">all shoes</a>
                      </li>
                      <li>
                        <a href="/products/shoes/men/insold">shoes on sale</a>
                      </li>
                      <br />
                      <li>
                        <a href="/products/shoes/men">shoes</a>
                      </li>
                      <li>
                        <a href="/products/shoes/men">flip-flop</a>
                      </li>
                    </ul>
                  </Row>
                  <Row>
                    <header>clothes</header>
                    <ul className="mega-links">
                      <li>
                        <a href="/products/clothes/men">all clothes</a>
                      </li>
                      <li>
                        <a href="/products/clothes/men/insold">
                          clothes on sale
                        </a>
                      </li>
                      <br />
                      <li>
                        <a href="/products/clothes/men">tracksuits</a>
                      </li>
                      <li>
                        <a href="/products/clothes/men">t-shirt</a>
                      </li>
                      <li>
                        <a href="/products/clothes/men">sweat</a>
                      </li>
                      <li>
                        <a href="/products/clothes/men">pantacourt</a>
                      </li>
                    </ul>
                  </Row>
                  <Row>
                    <header>accessories</header>
                    <ul className="mega-links">
                      <li>
                        <a href="/products/accessories/men">cap</a>
                      </li>
                    </ul>
                  </Row>
                </MegContent>
              </MegaBox>
            </ManLi>
            <li>
              <WemanLi>
                <DesktopMenuItem href="/products/all/women">
                  <b>women</b>
                </DesktopMenuItem>
              </WemanLi>
              <MegaBox>
                <MegContent>
                  <Row>
                    <header>shoes</header>
                    <ul className="mega-links">
                      <li>
                        <a href="/products/shoes/women">all shoes</a>
                      </li>
                      <li>
                        <a href="/products/shoes/women/insold">shoes on sale</a>
                      </li>
                      <br />
                      <li>
                        <a href="/products/shoes/women">shoes</a>
                      </li>
                      <li>
                        <a href="/products/shoes/women">flip-flop</a>
                      </li>
                    </ul>
                  </Row>
                  <Row>
                    <header>clothes</header>
                    <ul className="mega-links">
                      <li>
                        <a href="/products/clothes/women">all clothes</a>
                      </li>
                      <li>
                        <a href="/products/clothes/women/insold">
                          clothes on sale
                        </a>
                      </li>
                      <br />
                      <li>
                        <a href="/products/clothes/women">tracksuits</a>
                      </li>
                      <li>
                        <a href="/products/clothes/women">t-shirt</a>
                      </li>
                      <li>
                        <a href="/products/clothes/women">sweat</a>
                      </li>
                      <li>
                        <a href="/products/clothes/women">sandals</a>
                      </li>
                    </ul>
                  </Row>
                  <Row>
                    <header>accessories</header>
                    <ul className="mega-links">
                      <li>
                        <a href="/products/accessories/women">cap</a>
                      </li>
                    </ul>
                  </Row>
                </MegContent>
              </MegaBox>
            </li>
            <li>
              <KidLin>
                <DesktopMenuItem href="/products/all/kids">
                  <b>Kids</b>
                </DesktopMenuItem>
              </KidLin>
              <MegaBox>
                <MegContent>
                  <Row>
                    <header>shoes</header>
                    <ul className="mega-links">
                      <li>
                        <a href="/products/shoes/kids">all shoes</a>
                      </li>
                      <li>
                        <a href="/products/shoes/kids/insold">shoes on sale</a>
                      </li>
                      <br />
                      <li>
                        <a href="/products/shoes/kids">shoes</a>
                      </li>
                      <li>
                        <a href="/products/shoes/kids">flip-flop</a>
                      </li>
                    </ul>
                  </Row>
                  <Row>
                    <header>clothes</header>
                    <ul className="mega-links">
                      <li>
                        <a href="/products/clothes/kids">all clothes</a>
                      </li>
                      <li>
                        <a href="/products/clothes/kids/insold">
                          clothes on sale
                        </a>
                      </li>
                      <br />
                      <li>
                        <a href="/products/clothes/kids">tracksuits</a>
                      </li>
                      <li>
                        <a href="/products/clothes/kids">t-shirt</a>
                      </li>
                      <li>
                        <a href="/products/clothes/kids">sweat</a>
                      </li>
                      <li>
                        <a href="/products/clothes/kids">pantacourt</a>
                      </li>
                    </ul>
                  </Row>
                  <Row>
                    <header>accessories</header>
                    <ul className="mega-links">
                      <li>
                        <a href="/products/accessories/kids">cap</a>
                      </li>
                    </ul>
                  </Row>
                </MegContent>
              </MegaBox>
            </li>
            <li>
              <DesktopMenuItem
                href="/products/all/all/insold"
                style={{ color: "#eaad20" }}
              >
                <b>sale</b>
              </DesktopMenuItem>
            </li>
          </DesktopMenu>
        </Left>
        <Center>
          <Link to="/">
            <LOGOIMG>
              <Img src={logo} />
            </LOGOIMG>
          </Link>
        </Center>

        <Right>
          {/* <MenuItem>REGISTER</MenuItem>
          <Link to="/login">
            <MenuItem>SIGN IN</MenuItem>
          </Link> */}
          {/* <Link to="/cart"> */}
          <SearchContainer>
            <SearchInputDesk>
              <Input
                placeholder="Search"
                onChange={handleSearchChange}
                // onBlur={() => setActiveSearch(false)}
                // onFocus={() => setActiveSearch(true)}
              />
              <Search
                style={{
                  color: "black",
                  fontSize: 20,
                  position: "absolute",
                  right: 10,
                }}
              />
            </SearchInputDesk>
          </SearchContainer>
          <div style={{ display: "flex", gap: 15 }}>
            <MobileSearchIcon
              onClick={() => {
                setShowSearchMobile(!showSearchMobile);
                activeSearch && setActiveSearch(false);
              }}
            >
              {showSearchMobile ? null : <Search />}
            </MobileSearchIcon>
            <MenuItem
              onClick={handleSideClick}
              isSearchActive={showSearchMobile}
            >
              <Badge
                badgeContent={quantity}
                overlap="rectangular"
                color="primary"
              >
                <ShoppingCartOutlined />
              </Badge>
            </MenuItem>
          </div>
          {/* </Link> */}
        </Right>
      </Wrapper>

      <MobileMenu
        showMobileMenu={showMobileMenu}
        slideMobileMenu={slideMobileMenu}
      >
        <MobileMenuWrapper>
          <div style={{ padding: "20px 0 30px" }}>
            <Close
              onClick={() => setShowMobileMenu(false)}
              style={{ fontSize: 35 }}
            />
          </div>
          <MobileMenuItem onClick={() => handleMenuItemClick("all")}>
            New Arrivals <ArrowForwardIosOutlined />
          </MobileMenuItem>
          <MobileMenuItem onClick={() => handleMenuItemClick("men", false)}>
            Men <ArrowForwardIosOutlined />
          </MobileMenuItem>
          <MobileMenuItem onClick={() => handleMenuItemClick("women", false)}>
            Women <ArrowForwardIosOutlined />
          </MobileMenuItem>
          <MobileMenuItem onClick={() => handleMenuItemClick("kids", false)}>
            Kids <ArrowForwardIosOutlined />
          </MobileMenuItem>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={`/products/accessories/all/all`}
            onClick={() => setShowMobileMenu(false)}
          >
            <MobileMenuItem>Accessories</MobileMenuItem>
          </Link>
          <MobileMenuItem sale onClick={() => handleMenuItemClick("all", true)}>
            Sale <ArrowForwardIosOutlined />
          </MobileMenuItem>
        </MobileMenuWrapper>
        <MobileMenuWrapper>
          <div
            style={{
              padding: "20px 0 30px",
              display: "flex",
              alignItems: "center",
              gap: 20,
            }}
            onClick={() => setSlideMobileMenu(false)}
          >
            <ArrowBackIosOutlined style={{ fontSize: 25 }} />

            {/* <p style={{ fontSize: 20, fontWeight: 400 }}>Back</p> */}
            <p style={{ fontSize: 20 }}>All</p>
          </div>
          <p
            style={{
              fontSize: 25,
              fontWeight: 600,
              margin: "10px 0",
              textTransform: "capitalize",
              color: slideSelected === "sale" ? "#eaad20" : "black",
            }}
          >
            {slideSelected}
          </p>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={`/products/shoes/${slideSelected}/${
              soldSelected ? "insold/" : "all"
            }`}
            onClick={closeMobileMenu}
          >
            <MobileMenuItem> shoes</MobileMenuItem>
          </Link>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={`/products/clothes/${slideSelected}/${
              soldSelected ? "insold/" : "all"
            }`}
            onClick={closeMobileMenu}
          >
            <MobileMenuItem> clothes</MobileMenuItem>
          </Link>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={`/products/accessories/${slideSelected}/${
              soldSelected ? "insold/" : "all"
            }`}
            onClick={closeMobileMenu}
          >
            <MobileMenuItem> accessories</MobileMenuItem>
          </Link>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={`/products/all/${slideSelected}/insold/all`}
            onClick={closeMobileMenu}
          >
            <MobileMenuItem sale isSale={soldSelected}>
              {" "}
              sale{" "}
            </MobileMenuItem>
          </Link>
        </MobileMenuWrapper>
      </MobileMenu>
      {showSearchMobile && (
        <MobileSearchInput>
          <Input placeholder="Search" onChange={handleSearchChange} />
          <Close
            onClick={() => {
              setActiveSearch(false);
              setShowSearchMobile(false);
            }}
            style={{
              color: "black",
              fontSize: 25,
              position: "absolute",
              right: 10,
            }}
          />
        </MobileSearchInput>
      )}
      {activeSearch && (
        <ProductsContainer>
          <CloseContainer>
            <Close
              onClick={() => {
                setActiveSearch(false);
                setShowSearchMobile(false);
              }}
              style={{ fontSize: 23, cursor: "pointer" }}
            />
          </CloseContainer>
          <Products
            onClick={() => {
              setActiveSearch(false);
              setShowSearchMobile(false);
            }}
          >
            {products.length === 0 && (
              <Product>
                <ImgContainer></ImgContainer>
                <Info>
                  <Title>No prodcts found</Title>
                  <Price></Price>
                </Info>
              </Product>
            )}
            {products.map((item, index) => (
              <Link
                style={{
                  textDecoration: "none",
                }}
                to={`/product/${item._id}`}
                key={index}
              >
                <Product>
                  <ImgContainer>
                    <ProductImg src={BASE_URL + "media/image/" + item.img[0]} />
                  </ImgContainer>
                  <Info>
                    <Title>
                      {/* <Color color={item.color}></Color> */}
                      {item.title.length > 24
                        ? `${formatString(item.title).slice(0, 25)}...`
                        : formatString(item.title)}{" "}
                    </Title>
                    <Price>{item.price} DA</Price>
                  </Info>
                </Product>
              </Link>
            ))}
          </Products>
        </ProductsContainer>
      )}
    </Container>
  );
};

const MobileMenuItem = styled.div`
  display: ${(props) => (props.isSale ? "none" : "flex")};
  justify-content: space-between;
  padding: 10px;
  font-size: 25px;
  color: ${(props) => (props.sale ? "#eaad20" : "black")};
`;

const MobileMenuWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;
  width: 100vw;
`;

const MobileMenu = styled.div`
  position: fixed;
  top: ${(props) => (props.showMobileMenu ? 0 : "-100vh")};
  bottom: 0;
  /* right: 0; */
  left: 0;
  height: 100vh;
  display: flex;
  /* width: 200vw; */
  /* box-sizing: border-box; */
  z-index: 2;
  transform: ${(props) =>
    props.slideMobileMenu ? "translateX(-100vw)" : "translateX(0)"};
  background-color: #fff;
  transition: all 350ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
`;

const ResponsiveMenu = styled.div`
  display: none;
  cursor: pointer;
  ${mobile({
    display: "flex",
    visibility: (props) => (props.isSearchActive ? "hidden" : null),
    textAlign: "end",
  })}
`;

const MobileSearchInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const MobileSearchIcon = styled.div`
  display: none;
  cursor: pointer;
  ${mobile({
    display: "block",
  })}
`;

const SearchInputDesk = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  ${mobile({
    display: "none",
  })}
`;
const CloseContainer = styled.div`
  width: 100%;
  text-align: right;
  margin-right: 12px;
  margin-bottom: 5px;
  ${mobile({
    display: "none",
  })}
`;

const ProductImg = styled.img`
  max-width: 100%;
`;

const ImgContainer = styled.div`
  width: 50px;
  margin-right: 10px;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

const ProductsContainer = styled.div`
  position: absolute;
  background-color: #e0e0e0f0;
  backdrop-filter: blur(1px);
  padding: 10px 15px;
  border-radius: 10px;
  /* top: 90px; */
  right: 20px;
  width: 350px;
  ${mobile({
    position: "sticky",
    right: 0,
    left: 0,
    paddingTop: 40,
    // top: 117,
    width: "100%",
    boxSizing: "border-box",
  })}
`;
const Products = styled.div`
  /* min-width: 300px; */
`;
const Product = styled.div`
  p {
    color: black !important;
  }
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  background-color: #fafafa;
  /* border-bottom: 1px solid #d1d1d1; */
`;
const Title = styled.p`
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
`;
const Price = styled.p`
  font-weight: 400;
  font-size: 18px;
`;

const Img = styled.img`
  height: 100%;
`;
const LOGOIMG = styled.div`
  cursor: pointer;
  height: 35px;
  ${mobile({
    height: 30,
  })}
`;

const Container = styled.div`
  /* height: 60px; */
  /* margin-bottom: 30px; */
  position: sticky;
  top: 0;
  background-color: #fff;
  padding-bottom: 30px;
  z-index: 2;
  ${mobile({
    height: "50px",
    paddingBottom: 0,
    paddingTop: 5,
    marginBottom: 10,
  })}
`;
const Wrapper = styled.div`
  padding: 10px 20px 0px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 3px solid white;
  ${mobile({
    padding: "10px 20px",
    alignItems: "center",
  })}
`;

// ======left =============
const Left = styled.div`
  flex: 1;
  /* display: flex; */
  /* align-items: center; */
`;

const SearchContainer = styled.div`
  border: 0.5px solid lightgray;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-left: 25px;
  /* padding: 5px; */
`;
const Input = styled.input`
  flex: 1;
  min-width: 40%;
  background: #f5f5f5;
  /* margin: 20px 10px 0 0; */
  box-sizing: border-box;
  padding: 10px;
  ${mobile({
    backgound: "white",
    width: "100vw",
    padding: 15,
  })}
`;
// ======Center =============

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex: 1;
  text-align: center;
`;

// ======Right =============
const Right = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${mobile({
    flex: 1,
    // justifyContent: "center",
  })}
`;
const MenuItem = styled.div`
  font-size: 14;
  margin-left: 25px;
  visibility: ${(props) => (props.isSearchActive ? "hidden" : null)};
  cursor: pointer;
  ${mobile({
    fontSize: "14px",
    marginLeft: "0px",
  })}
`;

// ========== Desktop Menu =========
const ManLi = styled.li`
  &:hover {
    /* color: #eaad20 !important; */
  }
`;

const DesktopMenu = styled.ul`
  display: inline-flex;
  list-style: none;
  ${mobile({
    display: "none",
  })}
  a {
    /* font-size: 20px; */

    margin: 10px;
    cursor: pointer;
    /* text-transform: uppercase ; */
    text-decoration: none;
    &:hover {
      /* border-bottom: 1px solid black; */
    }
  }
`;

const MegaBox = styled.div`
  position: absolute;
  transform: translateY(40px);
  right: 0;
  left: 0;
  place-content: center;
  display: grid;
  opacity: 0;
  visibility: hidden;
  /* width: 80vw; */
  transition: all 300ms ease;
  &:hover {
    transform: translateY(13px);
    opacity: 1;
    visibility: visible;
  }
  /* margin: 0 auto;  */
  /* top: 150px; */
  /* left: 0; */
`;

const ManLink = styled.span`
  padding-bottom: 30px;
  &:hover {
    border-bottom: 3px solid black;
  }
  &:hover ~ ${MegaBox} {
    transform: translateY(13px);

    opacity: 1;
    visibility: visible;
  }
`;

const WemanLi = styled.span`
  padding-bottom: 30px;
  &:hover {
    border-bottom: 3px solid black;
  }
  &:hover ~ ${MegaBox} {
    transform: translateY(13px);

    opacity: 1;
    visibility: visible;
  }
`;

const KidLin = styled.span`
  padding-bottom: 30px;
  &:hover {
    border-bottom: 3px solid black;
  }
  &:hover ~ ${MegaBox} {
    transform: translateY(13px);

    opacity: 1;
    visibility: visible;
  }
`;

const Row = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      a {
        margin: 0;
        color: black;
      }
      margin-top: 5px;
    }
  }
`;

const MegContent = styled.div`
  background: #fff;
  padding: 50px 20px 25px;
  letter-spacing: 1.3px;
  line-height: 1.5;
  display: flex;
  width: 90vw;
  justify-content: center;
  gap: 150px;
  transform: translateY(20px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
`;

const DesktopMenuItem = styled.a`
  color: black;
  font-size: 17px;
  padding-bottom: 15px;
  text-transform: uppercase;
  &:hover ~ ${Row} {
    visibility: hidden;
  }
`;

export default Navbar;

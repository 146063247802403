import { configureStore } from "@reduxjs/toolkit"
import cartReducer from "./cartRedux"
import filterRedux from "./filterRedux"
import orderRedux from "./orderRedux"
import productRedux from "./productRedux"
import userReducer from "./userRedux"



export default configureStore({
    reducer: {
        cart: cartReducer,
        user: userReducer,
        order: orderRedux,
        filter: filterRedux,
        product: productRedux
    },
})


// import { Cart } from "./pages/Cart";
import Home from "./pages/Home";
// import { Login } from "./pages/Login";
import { Product } from "./pages/Product";
import { ProductList } from "./pages/ProductList";
// import { Register } from "./pages/Register";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Checkout from "./pages/checkout/Checkout";
import "./app.css";

import ScrollToTop from "./helpers/ScrollToTop";

const App = () => {
  // const user = useSelector((state) => state.user.currentUser);
  const quantity = useSelector((state) => state.cart.quantity);

  return (
    <Router>
      <ScrollToTop />
      {/* <Router>
        <CardSidebar/>
      </Router> */}
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/products/:category?">
          <ProductList />
        </Route>
        <Route path="/product/:id">
          <Product />
        </Route>
        {/* <Route path="/cart">
          <Cart />
        </Route> */}
        <Route path="/checkout">
          {quantity ? <Checkout /> : <Redirect to="/" />}
        </Route>
        {/* <Route path="/login">{user ? <Redirect to="/" /> : <Login />}</Route>
        <Route path="/register">
          {user ? <Redirect to="/" /> : <Register />}
        </Route> */}
      </Switch>
    </Router>
  );
};

export default App;

export function areArraysDifferent(arr1, arr2) {
    if (arr1?.length !== arr2?.length) {
      return true; // arrays have different length, can't have the same values
    }
  
    const sortedArr1 = arr1?.slice().sort();
    const sortedArr2 = arr2?.slice().sort();
  
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return true; // arrays have at least one different value
      }
    }
  
    return false; // arrays have the same values
  }
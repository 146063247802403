import { FilterList } from "@material-ui/icons";
import React, { useState } from "react";
import styled from "styled-components";
import DarkCheckMark from "../assets/svg/DarkCheckMark";
import { mobile } from "../responsive";
import { useDispatch, useSelector } from "react-redux";
import {
  addBrandsFiltered,
  addColorsFiltered,
  addSizesFiltered,
  addSort,
} from "../redux/filterRedux";
import { useHistory } from "react-router-dom";
import pathGenerator from "../utils/FilterPathGenerator";

const FilterSide = ({ filterOpen, setFilterOpen, cat, gender, sold }) => {
  //*initial settings:  1 || -1
  const [gendreToggle, setGendreToggle] = useState(1);
  const [colorToggle, setColorToggle] = useState(1);
  const [brandsToggle, setBrandsToggle] = useState(1);
  const [sizesToggle, setSizesToggle] = useState(1);
  const [sortToggle, setSortToggle] = useState(1);
  const filter = useSelector((state) => state.filter);

  const [colorsFiltered, setColorsFiltered] = useState([]);
  const [sizesFiltered, setSizesFiltered] = useState([]);
  const [brandsFiltered, setBrandsFiltered] = useState([
    ...filter.brandsFiltered,
  ]);
  const [categoryFiltered, setCategotyFiltered] = useState(
    cat === "clothes" || cat === "shoes" || cat === "accessories" ? cat : ""
  );
  const [genderFiltered, setGenderFiltered] = useState(
    gender.includes("all") ? "" : gender
  );
  const [inSold, setInSold] = useState(sold);
  const [sort, setSort] = useState("newest");

  const dispatch = useDispatch();
  const history = useHistory();
  const currentPath = window.location.pathname;

  function choosenColor(color) {
    if (colorsFiltered.includes(color)) {
      const colors = colorsFiltered.filter((item) => item !== color);
      setColorsFiltered(colors);
      dispatch(addColorsFiltered(colors));
    } else {
      setColorsFiltered((prev) => [...prev, color]);
      dispatch(addColorsFiltered([...colorsFiltered, color]));
    }
    setSizesFiltered([]);
  }
  function choosenSizes(size) {
    if (sizesFiltered.includes(size)) {
      const sizes = sizesFiltered.filter((item) => item !== size);
      setSizesFiltered(sizes);
      dispatch(addSizesFiltered(sizes));
    } else {
      setSizesFiltered((prev) => [...prev, size]);
      dispatch(addSizesFiltered([...sizesFiltered, size]));
    }
  }
  function choosenBrands(brand) {
    if (brandsFiltered.includes(brand)) {
      const brands = brandsFiltered.filter((item) => item !== brand);
      setBrandsFiltered(brands);
      dispatch(addBrandsFiltered(brands));
    } else {
      setBrandsFiltered((prev) => [...prev, brand]);
      dispatch(addBrandsFiltered([...brandsFiltered, brand]));
    }
    setSizesFiltered([]);
  }
  function choosenGenders(gender) {
    let genders = [];
    if (genderFiltered.includes(gender)) {
      genders = genderFiltered.filter((item) => item !== gender);
      setGenderFiltered(genders);
    } else {
      genders = [...genderFiltered, gender];
      setGenderFiltered(genders);
    }
    const newPath = pathGenerator(currentPath, {
      target: "gender",
      value: genders.join("-") || "all",
    });
    history.push(newPath);
    setSizesFiltered([]);
  }
  function choosenSort(sort) {
    setSort(sort);
    dispatch(addSort(sort));
  }
  function choosenSold() {
    const newPath = pathGenerator(currentPath, {
      target: "insold",
      value: !inSold ? "insold" : "all",
    });

    history.push(newPath);
    setInSold(!inSold);
    setSizesFiltered([]);
  }
  function choosenCategory(category) {
    let cat = category;
    if (categoryFiltered === category) {
      cat = "";
    }
    const newPath = pathGenerator(currentPath, {
      target: "cat",
      value: cat === "" ? "all" : category,
    });
    history.push(newPath);
    setCategotyFiltered(cat);
    setSizesFiltered([]);
  }

  // useEffect(() => {
  //   const getDistinct = async () => {
  //     try {
  //       const res = await publicRequest.get(`/products/distinct`);
  //       setDistincts(res.data);
  //       // setImages(res.data.img);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   getDistinct();
  // }, []);

  const products = useSelector((state) => state.product.products);

  //* filter distinct balue : brands & sizes
  const distinctSizes = [
    ...new Set(
      products
        .map((p) => p.attr.map((a) => a.size))
        .flat()
        .sort()
    ),
  ];
  const distinctBrands = [...new Set(products.map((p) => p.mark).flat())];
  //* dispatch the distinct values to redux *in order to use it in the filter side
  // console.log({ distinctBrands, distinctSizes });
  // dispatch(addDistinctSizes(distinctSizes));
  // dispatch(addDistinctBrands(distinctBrands));

  return (
    <Container filterOpen={filterOpen}>
      <h2>Filters</h2>
      <CloseContainer>
        <div style={{ position: "fixed" }}>
          <CloseBtn onClick={() => setFilterOpen(false)}>
            <CloseIcon />
          </CloseBtn>
        </div>
      </CloseContainer>
      <FilterItem>
        <FilterCat>
          <Cat
            onClick={() => choosenCategory("clothes")}
            isSelected={categoryFiltered === "clothes"}
          >
            clothes
          </Cat>
          <Cat
            onClick={() => choosenCategory("shoes")}
            isSelected={categoryFiltered === "shoes"}
          >
            shoes
          </Cat>
          <Cat
            onClick={() => choosenCategory("accessories")}
            isSelected={categoryFiltered === "accessories"}
          >
            accessories
          </Cat>
        </FilterCat>
      </FilterItem>
      <FilterItem>
        <FilterItemTitle onClick={() => setSortToggle(sortToggle * -1)}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <FilterList /> Sort
          </div>
          <p>{sortToggle > 0 ? "-" : "+"}</p>
        </FilterItemTitle>
        <FilterItemContent toggle={sortToggle}>
          <MyCheckBoxContainer>
            <MyCheckBoxForm onClick={() => choosenSort("newest")}>
              <MyCheckBox rounded isSelected={sort === "newest"}>
                {sort === "newest" && <CheckMark />}
              </MyCheckBox>
              <p>Newest</p>
            </MyCheckBoxForm>
            <MyCheckBoxForm onClick={() => choosenSort("high-low")}>
              <MyCheckBox rounded isSelected={sort === "high-low"}>
                {sort === "high-low" && <CheckMark />}
              </MyCheckBox>
              <p>Price: High-Low</p>
            </MyCheckBoxForm>
            <MyCheckBoxForm onClick={() => choosenSort("low-high")}>
              <MyCheckBox rounded isSelected={sort === "low-high"}>
                {sort === "low-high" && <CheckMark />}
              </MyCheckBox>
              <p>Price: Low-High</p>
            </MyCheckBoxForm>
            <MyCheckBoxForm onClick={() => choosenSold()}>
              <MyCheckBox rounded sold isSelected={inSold}>
                {inSold && <CheckMark />}
              </MyCheckBox>
              <p>Sold</p>
            </MyCheckBoxForm>
          </MyCheckBoxContainer>
        </FilterItemContent>
      </FilterItem>
      <FilterItem>
        <FilterItemTitle onClick={() => setGendreToggle(gendreToggle * -1)}>
          Gender <p>{gendreToggle > 0 ? "-" : "+"}</p>
        </FilterItemTitle>
        <FilterItemContent toggle={gendreToggle}>
          <MyCheckBoxContainer>
            <MyCheckBoxForm onClick={() => choosenGenders("men")}>
              <MyCheckBox isSelected={genderFiltered.includes("men")}>
                {genderFiltered.includes("men") && <CheckMark />}
              </MyCheckBox>
              <p>Men</p>
            </MyCheckBoxForm>
            <MyCheckBoxForm onClick={() => choosenGenders("women")}>
              <MyCheckBox isSelected={genderFiltered.includes("women")}>
                {genderFiltered.includes("women") && <CheckMark />}
              </MyCheckBox>
              <p>Women</p>
            </MyCheckBoxForm>
            <MyCheckBoxForm onClick={() => choosenGenders("unisex")}>
              <MyCheckBox isSelected={genderFiltered.includes("unisex")}>
                {genderFiltered.includes("unisex") && <CheckMark />}
              </MyCheckBox>
              <p>Unisex</p>
            </MyCheckBoxForm>
            <MyCheckBoxForm onClick={() => choosenGenders("kids")}>
              <MyCheckBox isSelected={genderFiltered.includes("kids")}>
                {genderFiltered.includes("kids") && <CheckMark />}
              </MyCheckBox>
              <p>Kids</p>
            </MyCheckBoxForm>
          </MyCheckBoxContainer>
        </FilterItemContent>
      </FilterItem>
      <FilterItem>
        <FilterItemTitle onClick={() => setColorToggle(colorToggle * -1)}>
          Color <p>{colorToggle > 0 ? "-" : "+"}</p>
        </FilterItemTitle>
        <FilterItemContent toggle={colorToggle}>
          <GridCenter>
            <Sizes>
              <Color onClick={() => choosenColor("purple")}>
                <ColorCircle color="#8D429F">
                  {colorsFiltered.includes("purple") && <CheckMark />}
                </ColorCircle>
                <ColorName>Purple</ColorName>
              </Color>
              <Color onClick={() => choosenColor("black")}>
                <ColorCircle color="#111">
                  {colorsFiltered.includes("black") && <CheckMark />}
                </ColorCircle>
                <ColorName>Black</ColorName>
              </Color>
              <Color onClick={() => choosenColor("red")}>
                <ColorCircle color="#E7352B">
                  {colorsFiltered.includes("red") && <CheckMark />}
                </ColorCircle>
                <ColorName>Red</ColorName>
              </Color>
              <Color onClick={() => choosenColor("orange")}>
                <ColorCircle color="#F36B26">
                  {colorsFiltered.includes("orange") && <CheckMark />}
                </ColorCircle>
                <ColorName>Orange</ColorName>
              </Color>
              <Color onClick={() => choosenColor("blue")}>
                <ColorCircle color="#1790C8">
                  {colorsFiltered.includes("blue") && <CheckMark />}
                </ColorCircle>
                <ColorName>Blue</ColorName>
              </Color>
              <Color onClick={() => choosenColor("white")}>
                <ColorCircle color="#fff">
                  {colorsFiltered.includes("white") && <DarkCheckMark />}
                </ColorCircle>
                <ColorName>White</ColorName>
              </Color>
              <Color onClick={() => choosenColor("brown")}>
                <ColorCircle color="#825D41">
                  {colorsFiltered.includes("brown") && <CheckMark />}
                </ColorCircle>
                <ColorName>Brown</ColorName>
              </Color>
              <Color onClick={() => choosenColor("green")}>
                <ColorCircle color="#7BBA3C">
                  {colorsFiltered.includes("green") && <CheckMark />}
                </ColorCircle>
                <ColorName>Green</ColorName>
              </Color>
              <Color onClick={() => choosenColor("mix")}>
                <ColorCircle color="">
                  {colorsFiltered.includes("mix") && <DarkCheckMark />}
                </ColorCircle>
                <ColorName>Multi-Color</ColorName>
              </Color>
              <Color onClick={() => choosenColor("grey")}>
                <ColorCircle color="#808080">
                  {colorsFiltered.includes("grey") && <CheckMark />}
                </ColorCircle>
                <ColorName>Grey</ColorName>
              </Color>
              <Color onClick={() => choosenColor("pink")}>
                <ColorCircle color="#F0728F">
                  {colorsFiltered.includes("pink") && <CheckMark />}
                </ColorCircle>
                <ColorName>Pink</ColorName>
              </Color>
              <Color onClick={() => choosenColor("yellow")}>
                <ColorCircle color="#ffee00">
                  {colorsFiltered.includes("yellow") && <CheckMark />}
                </ColorCircle>
                <ColorName>Yellow</ColorName>
              </Color>
            </Sizes>
          </GridCenter>
        </FilterItemContent>
      </FilterItem>
      <FilterItem>
        <FilterItemTitle onClick={() => setBrandsToggle(brandsToggle * -1)}>
          Brands <p>{brandsToggle > 0 ? "-" : "+"}</p>
        </FilterItemTitle>
        <FilterItemContent toggle={brandsToggle}>
          <MyCheckBoxContainer>
            {distinctBrands.map((brand, index) => (
              <MyCheckBoxForm onClick={() => choosenBrands(brand)} key={index}>
                <MyCheckBox isSelected={brandsFiltered.includes(brand)}>
                  {brandsFiltered.includes(brand) && <CheckMark />}
                </MyCheckBox>
                <p>{brand}</p>
              </MyCheckBoxForm>
            ))}
          </MyCheckBoxContainer>
        </FilterItemContent>
      </FilterItem>
      <FilterItem>
        <FilterItemTitle onClick={() => setSizesToggle(sizesToggle * -1)}>
          Sizes <p>{sizesToggle > 0 ? "-" : "+"}</p>
        </FilterItemTitle>
        <FilterItemContent toggle={sizesToggle}>
          <GridCenter>
            <Sizes>
              {distinctSizes.map((size, index) => (
                <Size
                  key={index}
                  onClick={() => choosenSizes(size)}
                  isSelected={sizesFiltered.includes(size)}
                >
                  {size}
                </Size>
              ))}
            </Sizes>
          </GridCenter>
        </FilterItemContent>
      </FilterItem>
    </Container>
  );
};

const CloseContainer = styled.div`
  display: none;
  position: absolute;
  top: 15px;
  right: 20px;
  width: 100%;
  justify-content: right;
  ${mobile({
    display: "flex",
  })}
`;

const CloseBtn = styled.div`
  width: 40px;
  height: 40px;

  position: relative;
  cursor: pointer;
  transition: transform 250ms ease-in-out 0s;
  &:hover {
    transform: rotate(90deg);
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 40px;
  box-shadow: 0px 0px 5px rgba(70, 70, 70, 0.185);
  background-color: white;
  padding: 8px;
  font-size: 0;
  &::after,
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3px;
    height: 30px;
    background-color: black;
    transform: rotate(45deg) translate(-50%, -50%);
    transform-origin: top left;
    content: "";
  }
  &::after {
    transform: rotate(-45deg) translate(-50%, -50%);
  }
`;

const MyCheckBox = styled.div`
  width: 20px;
  height: 20px;
  /* vertical-align: top; */
  border: solid 1px #cccccc;
  border-radius: ${(props) => (props.rounded ? "10px" : "4px")};
  margin-right: 6px;
  /* display: inline-block; */
  /* position: relative; */
  background-color: ${(props) =>
    props.isSelected ? (props.sold ? "rgb(234, 173, 32)" : "black") : "white"};
  box-sizing: border-box;
  padding: 2px;
`;

const MyCheckBoxForm = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  cursor: pointer;
  p {
    font-size: 17px;
    text-transform: capitalize;
  }
`;

const MyCheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`;

const CheckMark = () => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{
        enableBackground: "new 0 0 24 24",
      }}
      xmlSpace="preserve"
    >
      <style type="text/css">{"\n\t.st0{fill:#FFFFFF;}\n"}</style>
      <path
        className="st0"
        d="M20.3,2L9,13.6l-5.3-5L0,12.3L9,21L24,5.7L20.3,2z"
      />
    </svg>
  );
};

const ColorName = styled.p`
  padding-top: 2px;
  font-size: 14px;
`;
const Color = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const ColorCircle = styled.div`
  width: 28px;
  height: 28px;
  text-align: center;
  border-radius: 14px;
  border: 1px solid #1111;
  box-sizing: border-box;
  padding: 5px;
  background-color: ${(props) => props.color};
`;
const GridCenter = styled.div`
  display: grid;
  place-content: center;
`;
const Sizes = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 7px;
  /* width: 280px; */
  margin-bottom: 30px;
  margin-top: 20px;
  padding: 2px;
  border: 1px solid
    ${(props) => (props.clickable === "red" ? "#631212" : "transparent")};
`;
const Size = styled.div`
  max-width: 80px;
  min-width: 50px;
  display: grid;
  place-content: center;
  font-weight: 500;
  border: 2px solid black;
  height: 50px;
  box-sizing: border-box;
  color: ${(props) => (props.isSelected ? "white" : "black")};
  background-color: ${(props) => (props.isSelected ? "#212a2f" : "white")};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => (props.isSelected ? "#000000" : "#bebebe")};
  }
`;
const FilterItemContent = styled.div`
  height: ${(props) => (props.toggle > 0 ? "100%" : "0")};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  visibility: ${(props) => (props.toggle > 0 ? "visible" : "hidden")};
`;

const FilterItemTitle = styled.h3`
  padding: 20px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;
const Cat = styled.a`
  display: block;
  font-size: 17px;
  color: #111111;
  font-weight: ${(props) => (props.isSelected ? "700" : "500")};
  line-height: 1.35em;
  letter-spacing: 1px;
  padding-bottom: 10px;
  padding-right: 1.1em;
  text-transform: capitalize;
  text-decoration: ${(props) => (props.isSelected ? "underline" : "none")};
  cursor: pointer;
`;
const FilterCat = styled.div``;

const FilterItem = styled.div`
  /* padding: 20px; */

  border-bottom: 1px solid #aeaeae;
  ${mobile({
    paddingInline: 20,
  })}
`;

const Container = styled.div`
  position: sticky;
  top: 120px;
  height: 80vh;
  overflow-y: scroll;
  min-width: 300px;
  /* max-width: 350px; */
  margin-right: 30px;
  padding-right: 20px;
  padding-top: 10px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  h2 {
    letter-spacing: 1px;
    padding-bottom: 20px;
    ${mobile({
      padding: "20px 15px",
    })}
  }
  ${mobile({
    position: "fixed",
    top: 0,
    left: "-100vw",
    minWidth: "100vw",
    height: "100vh",
    zIndex: 999,
    backgroundColor: "white",
    marginRight: 0,
    paddingRight: 0,
    transition: "all 350ms cubic-bezier(0.445, 0.05, 0.55, 0.95)",
    boxSizing: "border-box",
  })}
  @media only screen and (max-width: 420px) {
    left: ${(props) => (props.filterOpen ? "0" : "-100vw")};
  }
`;

export default FilterSide;

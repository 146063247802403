export default function pathGenerator(currentPath, filter) {
    const path = currentPath.split("/")
    switch (filter.target) {
        case "cat":
            path[2] = filter.value
            break;
        case "gender":
            if (!path[2]) {
                path[2] = 'all'
            }
            path[3] = filter.value

            break;
        case "insold":
            if (!path[2]) {
                path[2] = 'all'
            }
            if (!path[3]) {
                path[3] = 'all'
            }
            path[4] = filter.value

            break;
        case "souCat":
            if (!path[2]) {
                path[2] = 'all'
            }
            if (!path[3]) {
                path[3] = 'all'
            }
            if (!path[4]) {
                path[4] = 'all'
            }
            path[5] = filter.value

            break;
        default:
            break;
    }
    return path.join("/")
}

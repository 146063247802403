import axios from "axios"

// const url = "https://noah-api.onrender.com"
// const url = "http://localhost:5000"
const url = "https://api.noahstoredz.com"

// const url = "https://bumpy-wasps-draw-105-107-27-163.loca.lt/"


export const BASE_URL = `${url}/api/`

const TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzODBiYmI2MDA4OTYzYjQ5ODkxZjE5MiIsImlzQWRtaW4iOnRydWUsImlhdCI6MTY3MDE5MTMzNSwiZXhwIjoxNjcwNDUwNTM1fQ.B4i3otwQw0bUcBLre784_T9illS_0niYxsaIGLdF77s"

export const publicRequest = axios.create({
    baseURL: BASE_URL,
})

export const userRequest = axios.create({
    baseURL: BASE_URL,
    headers: { token: `Bearer ${TOKEN}` }
})
import { createSlice } from '@reduxjs/toolkit'


const orderSlice = createSlice({
    name: "order",
    initialState: {
        oreder: {},
        isFetching: false,
        error: false,
        openSuccessPage: false,
    },
    reducers: {
        addOrderStart: (state) => {
            state.isFetching = true
        },
        addOrderSuccess: (state, action) => {
            state.isFetching = false
            state.oreder = action.payload
            state.error = false
            state.openSuccessPage = true
        },
        addOrderFailure: (state) => {
            state.isFetching = false
            state.error = true
        },
        closeSuccessPage: (state) => {
            state.openSuccessPage = false
        }
        // addToWishList: (state, action) => {
        //     state.wishList.push(action.payload)
        // }
    }
})

export const { addOrderStart, addOrderSuccess, addOrderFailure, closeSuccessPage } = orderSlice.actions
export default orderSlice.reducer
import React from "react";
import styled from "styled-components";
import noahLogo from "../../assets/LOGO2.png";
import { mobile } from "../../responsive";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../requestMethods";

const AddedToCart = ({ product, quantity, size, handleCartOpen }) => {
  return (
    <Container>
      <Card>
        <CloseContainer>
          <CloseBtn onClick={handleCartOpen}>
            <CloseIcon />
          </CloseBtn>
        </CloseContainer>
        <ImgContainer>
          <LogoImg src={noahLogo} />
        </ImgContainer>
        <h1
          style={{
            paddingBlock: 20,
          }}
        >
          Item added to cart!
        </h1>
        <DarkContainer>
          <Product>
            <ProductImg>
              <ProductQuantity>{quantity}</ProductQuantity>
              <Img src={BASE_URL + "media/image/" + product.img[0]} />
            </ProductImg>
            <Info>
              <ProductTitle>{product.title}</ProductTitle>
              <ProductSize>{size}</ProductSize>
            </Info>
            <ProductPrice>
              DA{" "}
              {product.sold?.inSold ? product.sold?.soldPrice : product.price}
            </ProductPrice>
          </Product>
          <TwoInputs>
            <Link to="/checkout" style={{ all: "unset" }}>
              <Button>check out</Button>
            </Link>
            <ContinueButton onClick={handleCartOpen}>
              countinue shopping
            </ContinueButton>
          </TwoInputs>
        </DarkContainer>
      </Card>
    </Container>
  );
};

const ContinueButton = styled.button`
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  padding: 12px 18px;
  font-weight: 700;
  border-radius: 5px;
  color: #212a2f;
  background-color: white;
  transition: color 0.25s ease-out, background-color 0.25s ease-out,
    border-color 0.25s ease-out;
  appearance: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Button = styled.button`
  display: block;
  font-size: 16px;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  padding: 12px 18px;
  font-weight: 700;
  border: 2px solid #212a2f;
  border-radius: 5px;
  color: #fff;
  background-color: #212a2f;
  transition: color 0.25s ease-out, background-color 0.25s ease-out,
    border-color 0.25s ease-out;
  appearance: none;
  cursor: pointer;
  &:hover {
    background-color: #212a2fea;
    /* color: #212a2f; */
    font-weight: 700;
  }
`;

const TwoInputs = styled.div`
  display: ${(props) => (props.submitMobile ? "none" : "grid")};
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 10px;
  margin-top: 15px;
  a {
    color: black;
    text-align: center;
    letter-spacing: 1.1px;
    font-size: 16px;
    font-weight: bold;
  }
  @media only screen and (max-width: 420px) {
    display: ${(props) => (props.submit ? "none" : "grid")};
    display: ${(props) => (props.submitMobile ? "grid !important" : null)};
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ProductQuantity = styled.div`
  position: absolute;
  top: -8px;
  left: -8px;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background-color: #eaad20;
  color: white;
  display: grid;
  place-content: center;
`;

const Img = styled.img`
  width: 100%;
  object-fit: contain;
  box-sizing: border-box;
  border: 1px solid grey;
  border-radius: 9px;
`;

const Product = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  color: #323232;
  margin-bottom: 7px;
  box-sizing: border-box;
  padding-inline: 10px;
`;

const ProductImg = styled.div`
  width: 110px;
  border-radius: 8px;
  background: transparent;
  margin-right: 20px;
  position: relative;
  ${mobile({
    width: 90,
    marginRight: 10,
  })}
`;

const ProductPrice = styled.div`
  width: 110px;
  display: flex;
  justify-content: right;
  font-weight: 700;
  ${mobile({
    fontSize: 14
  })}
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
`;
const ProductTitle = styled.div`
  font-size: 16px;
  line-height: 1.2;
  font-weight: 800;
  ${mobile({
    fontSize: 14
  })}
`;
const ProductSize = styled.div`
  font-size: 0.8571428571em;
  font-weight: 500;
  color: #717171;
  text-transform: uppercase;
`;

const DarkContainer = styled.div`
  width: calc(100% - 20px);
  /* background-color: #eeeeee; */
  color: white;
  padding: 20px 10px;
  display: grid;
  place-content: center;
  letter-spacing: 1.5px;
  /* box-sizing: border-box; */
  h2 {
    text-align: center;
    margin-bottom: 10px;
    ${mobile({
      fontSize: 18,
      padding: "0 15px",
      textAlign: "center",
    })}
  }
  p {
    margin: 8px 5px;
    padding: 0 15px;
    ${mobile({
      fontSize: 13,
      // padding: "0 15px",
    })}
  }
`;

const Card = styled.div`
  width: 500px;
  background-color: #fff;
  padding: 20px 20px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${mobile({
    width: "85vw",
    padding: "15px 5px",
  })}
`;

const ImgContainer = styled.div`
  width: 45%;
  display: block;
`;

const LogoImg = styled.img`
  width: 100%;
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  display: grid;
  place-content: center;
  background-color: rgba(0, 0, 0, 0.61);
  backdrop-filter: blur(5px);
  overflow: hidden;
  ${mobile({
    // padding: 20
  })}

  h1 {
    margin: 20px;
    letter-spacing: 0.8px;
    font-weight: 500;
    /* font-size: 16px; */
    ${mobile({
      fontSize: 28,
    })}
  }
`;

const CloseContainer = styled.div`
  position: absolute;
  right: 30px;
  top: 20px;
  display: flex;
  justify-content: right;
  ${mobile({
    top: 10,
    right: 5,
  })}
`;

const CloseBtn = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  transition: transform 250ms ease-in-out 0s;
  &:hover {
    transform: rotate(90deg);
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 50px;
  height: 50px;
  font-size: 0;
  &::after,
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 36px;
    background-color: black;
    transform: rotate(45deg) translate(-50%, -50%);
    transform-origin: top left;
    content: "";
  }
  &::after {
    transform: rotate(-45deg) translate(-50%, -50%);
  }
`;
export default AddedToCart;

import styled from 'styled-components'
import { categories } from '../data'
import { mobile } from '../responsive'
import { CategoryItem } from './CategoryItem'
const Container = styled.div`
    display: flex;
    padding: 20px;
    justify-content: space-between;
    ${mobile({ 
      width: "95%",
      padding: "0" ,
      flexDirection: "column"
    })}
`
export const Categories = () => {
  return (
    <Container>
        {
            categories.map(item => (
                <CategoryItem item={item} key={item.id}/>
            ))
        }
    </Container>
  )
}

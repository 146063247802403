const CheckMark = () => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{
        enableBackground: "new 0 0 24 24",
      }}
      xmlSpace="preserve"
    >
      <style type="text/css">{"\n\t.st0{fill:#FFFFFF;}\n"}</style>
      <path
        className="st1"
        d="M20.3,2L9,13.6l-5.3-5L0,12.3L9,21L24,5.7L20.3,2z"
      />
    </svg>
  );
};
export default CheckMark
